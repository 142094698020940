export default function NavigationButtons({ 
    onPrevious, 
    onNext, 
    currentStep, 
    canGoNext, 
    isLastStep = false,
    lastStepLabel = "예약하기" 
  }) {
    return (
      <div className="flex justify-between gap-4">
        <button
          onClick={onPrevious}
          className="flex-1 py-3 text-gray-600 transition-colors border rounded-lg hover:bg-gray-50"
        >
          이전
        </button>
        {!isLastStep ? (
          <button
            onClick={onNext}
            disabled={!canGoNext}
            className="flex-1 py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800 disabled:bg-gray-400"
          >
            다음
          </button>
        ) : (
          <button
            onClick={onNext}
            className="flex-1 py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
          >
            {lastStepLabel}
          </button>
        )}
      </div>
    );
  }