import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { CircleIcon, UsersIcon, CalendarIcon, ClockIcon } from "lucide-react";

export default function DashboardStats({ stats = {} }) {
  const statCards = [
    {
      title: "대기 중인 투어 예약",
      value: stats.pending_tours || 0,
      icon: <ClockIcon className="w-4 h-4 text-sky-600" />,
      color: "text-sky-600",
      bgColor: "bg-sky-50"
    },
    {
      title: "대기 중인 미팅 예약",
      value: stats.pending_meetings || 0,
      icon: <UsersIcon className="w-4 h-4 text-emerald-600" />,
      color: "text-emerald-600",
      bgColor: "bg-emerald-50"
    },
    {
      title: "오늘의 투어",
      value: stats.today_tours || 0,
      icon: <CircleIcon className="w-4 h-4 text-sky-600" />,
      color: "text-sky-600",
      bgColor: "bg-sky-50"
    },
    {
      title: "오늘의 미팅",
      value: stats.today_meetings || 0,
      icon: <CalendarIcon className="w-4 h-4 text-emerald-600" />,
      color: "text-emerald-600",
      bgColor: "bg-emerald-50"
    }
  ];

  return (
    <div className="grid gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4">
      {statCards.map((stat, index) => (
        <Card key={index} className="transition-all duration-200 hover:shadow-md">
          <CardHeader className="flex flex-row items-center justify-between space-y-0">
            <CardTitle className="text-sm font-medium text-gray-600">
              {stat.title}
            </CardTitle>
            <div className={`p-2 rounded-full ${stat.bgColor}`}>
              {stat.icon}
            </div>
          </CardHeader>
          <CardContent>
            <div className={`text-2xl font-bold ${stat.color}`}>
              {stat.value.toLocaleString()}
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}