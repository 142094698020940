import { useState, useEffect } from 'react';
import { Upload, Trash2, Pencil, X, Building2, User, Phone, Briefcase } from "lucide-react";
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Card } from '../../ui/card'

// 전화번호 형식화 함수
const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  
  // 숫자만 추출
  const numbers = phone.replace(/[^0-9]/g, '');
  
  // 010-1234-5678 형식으로 변환
  if (numbers.length === 11) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7)}`;
  }
  return phone;
};

// 전화번호에서 하이픈 제거하는 함수
const removeHyphens = (phone) => {
  return phone.replace(/-/g, '');
};

// 전화번호 유효성 검사 함수
const isValidPhoneNumber = (phone) => {
  const numbers = phone.replace(/-/g, '');
  return /^010\d{8}$/.test(numbers);
};

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // 사용자 목록 조회
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`/api/admin/users/?search=${searchQuery}&page=${page}`);
      
      // 응답 데이터가 있는지 확인
      if (response.data) {
        // 응답이 배열인 경우 직접 사용하고, 아닌 경우 results 필드 확인
        const results = Array.isArray(response.data) ? response.data : response.data.results || [];
        setUsers(results);
        // 배열인 경우 전체 길이를 count로 사용
        const totalCount = Array.isArray(response.data) ? response.data.length : (response.data.count || 0);
        setTotalItems(totalCount);
        setTotalPages(Math.ceil(totalCount / 10));
        // 에러 상태 초기화
        setError('');
      } else {
        setUsers([]);
        setTotalItems(0);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('사용자 목록을 불러오는데 실패했습니다.');
      setUsers([]);
      setTotalItems(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  // 검색 핸들러
  const handleSearch = (value) => {
    setSearchQuery(value);
    setPage(1); // 검색 시 첫 페이지로 초기화
  };

  useEffect(() => {
    fetchUsers();
  }, [searchQuery, page]);

  // 페이지 변경 핸들러
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setSelectedUserIds([]); // 페이지 변경 시 선택 초기화
  };

  // 전체 선택 처리
  const handleSelectAll = (e) => {
    if (e.target.checked && users) {
      setSelectedUserIds(users.map(user => user.id));
    } else {
      setSelectedUserIds([]);
    }
  };

  // 개별 선택 처리
  const handleSelectUser = (userId) => {
    setSelectedUserIds(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  // Excel 파일 업로드 처리
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsLoading(true);
      await api.post('/api/admin/users/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setPage(1); // 업로드 후 첫 페이지로 이동
      await fetchUsers(); // await 추가
      toast.success('사용자가 성공적으로 등록되었습니다.');
    } catch (error) {
      if (error.response?.data?.details) {
        const errorDetails = error.response.data.details
          .map(err => `Row ${err.row}: ${JSON.stringify(err.errors)}`)
          .join('\n');
        toast.error(`엑셀 파일 처리 중 오류가 발생했습니다:\n${errorDetails}`);
      } else {
        toast.error('파일 업로드에 실패했습니다.');
      }
    } finally {
      setIsLoading(false);
      event.target.value = '';
    }
  };

  // 선택된 사용자 삭제
  const handleBulkDelete = async () => {
    if (selectedUserIds.length === 0) return;
    
    const confirmMessage = selectedUserIds.length === users.length
      ? '모든 사용자를 삭제하시겠습니까?'
      : `선택한 ${selectedUserIds.length}명의 사용자를 삭제하시겠습니까?`;
    
    if (!window.confirm(confirmMessage)) return;

    try {
      await api.delete('/api/admin/users/', {
        data: { user_ids: selectedUserIds }
      });
      setSelectedUserIds([]);
      await fetchUsers(); // await 추가
      toast.success('선택한 사용자들이 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 전체 사용자 삭제
  const handleDeleteAll = async () => {
    if (!window.confirm('정말로 모든 사용자를 삭제하시겠습니까?')) return;

    try {
      await api.delete('/api/admin/users/');
      setPage(1); // 삭제 후 첫 페이지로 이동
      await fetchUsers(); // await 추가
      toast.success('모든 사용자가 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 단일 사용자 삭제
  const handleDelete = async (userId) => {
    if (!window.confirm('정말로 이 사용자를 삭제하시겠습니까?')) return;

    try {
      await api.delete(`/api/admin/users/${userId}/`);
      await fetchUsers(); // await 추가
      toast.success('사용자가 삭제되었습니다.');
    } catch (error) {
      toast.error('사용자 삭제에 실패했습니다.');
    }
  };

  // 사용자 수정 모달 열기
  const openEditModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-8">
        {/* 헤더 섹션 */}
        <div className="mb-8 bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-medium text-gray-900">회원 관리</h2>
          </div>
          
          <div className="p-6">
            <div className="flex items-center justify-between">
              {/* 검색창 */}
              <div className="relative w-96">
                <input
                  type="text"
                  placeholder="이름, 회사, 코드, 전화번호로 검색"
                  className="w-full py-2 pl-4 pr-4 text-sm border border-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              
              {/* 액션 버튼들 */}
              <div className="flex space-x-3">
                {selectedUserIds.length > 0 && (
                  <button
                    onClick={handleBulkDelete}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    선택 삭제 ({selectedUserIds.length})
                  </button>
                )}
                <button
                  onClick={handleDeleteAll}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <Trash2 className="w-4 h-4 mr-2" />
                  전체 삭제
                </button>
                <div>
                  <input
                    type="file"
                    id="file-upload"
                    accept=".xlsx,.xls,.csv"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                  <label
                    htmlFor="file-upload"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    <Upload className="w-4 h-4 mr-2 text-gray-500" />
                    Excel 등록
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {error && (
          <div className="p-4 mb-6 text-red-700 bg-red-100 rounded-md">
            {error}
          </div>
        )}
  
        {/* 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className="py-3 pl-6">
                    <input
                      type="checkbox"
                      checked={users && users.length > 0 && selectedUserIds.length === users.length}
                      onChange={handleSelectAll}
                      disabled={!users || users.length === 0}
                      className="w-4 h-4 border-gray-300 rounded text-sky-600 focus:ring-sky-500"
                    />
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    회사명
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    이름
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    연락처
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    직책
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                    코드
                  </th>
                  <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 uppercase bg-gray-50">
                    관리
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center">
                      <div className="flex items-center justify-center">
                        <div className="w-6 h-6 border-t-2 border-b-2 rounded-full border-sky-500 animate-spin"></div>
                        <span className="ml-2">로딩 중...</span>
                      </div>
                    </td>
                  </tr>
                ) : !users || users.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                      등록된 회원이 없습니다.
                    </td>
                  </tr>
                ) : (
                  users.map((user) => (
                    <tr key={user.id || user.code} className="hover:bg-gray-50">
                      <td className="py-4 pl-6">
                        <input
                          type="checkbox"
                          checked={selectedUserIds.includes(user.id)}
                          onChange={() => handleSelectUser(user.id)}
                          className="w-4 h-4 border-gray-300 rounded text-sky-600 focus:ring-sky-500"
                        />
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.company_name || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.name || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {formatPhoneNumber(user.phone) || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.position || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {user.code || '-'}
                      </td>
                      <td className="px-6 py-4 text-sm text-center">
                        <div className="flex justify-center space-x-3">
                          <button
                            onClick={() => openEditModal(user)}
                            className="transition-colors text-sky-600 hover:text-sky-900"
                            title="수정"
                          >
                            <Pencil className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDelete(user.id)}
                            className="text-red-600 transition-colors hover:text-red-900"
                            title="삭제"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
  
          {/* 페이지네이션 */}
          <div className="px-6 py-4 border-t border-gray-200 bg-gray-50">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  전체 <span className="font-medium">{totalItems}</span> 명
                  {totalItems > 0 && (
                    <>
                      중 <span className="font-medium">{((page - 1) * 10) + 1}</span> 부터{' '}
                      <span className="font-medium">{Math.min(page * 10, totalItems)}</span> 까지
                    </>
                  )}
                </p>
              </div>
              
              {totalPages > 1 && (
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handlePageChange(1)}
                    disabled={page === 1}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === 1
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    처음
                  </button>
                  
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === 1
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    이전
                  </button>
  
                  <div className="flex items-center space-x-1">
                    {[...Array(totalPages)].map((_, i) => {
                      if (
                        i + 1 === 1 ||
                        i + 1 === totalPages ||
                        (i + 1 >= page - 2 && i + 1 <= page + 2)
                      ) {
                        return (
                          <button
                            key={i}
                            onClick={() => handlePageChange(i + 1)}
                            className={`px-3 py-1 text-sm rounded-md ${
                              page === i + 1
                                ? 'bg-sky-600 text-white'
                                : 'text-gray-700 hover:bg-gray-100'
                            }`}
                          >
                            {i + 1}
                          </button>
                        );
                      }
                      
                      if (i + 1 === page - 3 || i + 1 === page + 3) {
                        return (
                          <span key={i} className="px-2 py-1 text-gray-500">
                            ...
                          </span>
                        );
                      }
                      
                      return null;
                    })}
                  </div>
  
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === totalPages
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    다음
                  </button>
  
                  <button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={page === totalPages}
                    className={`px-2 py-1 text-sm rounded-md ${
                      page === totalPages
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    마지막
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 수정 모달 */}
      {showModal && (
        <UserEditModal
          user={selectedUser}
          onClose={() => {
            setShowModal(false);
            setSelectedUser(null);
          }}
          onSave={async (updatedData) => {
            try {
              await api.put(`/api/admin/users/${selectedUser.id}/`, updatedData, {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              await fetchUsers(); // await 추가
              setShowModal(false);
              setSelectedUser(null);
              toast.success('사용자 정보가 수정되었습니다.');
            } catch (error) {
              console.error('Error:', error.response?.data || error);
              toast.error('사용자 정보 수정에 실패했습니다.');
            }
          }}
        />
      )}
    </div>
  );
}

// 사용자 수정 모달 컴포넌트
function UserEditModal({ user, onClose, onSave }) {
  const [formData, setFormData] = useState({
    id: user.id,
    name: user.name,
    code: user.code,
    phone: formatPhoneNumber(user.phone),
    company_name: user.company_name,
    position: user.position,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error('올바른 전화번호 형식이 아닙니다.');
      return;
    }

    onSave({
      ...formData,
      phone: removeHyphens(formData.phone)
    });
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d-]/g, '');
    const numbers = value.replace(/-/g, '');
    if (numbers.length <= 11) {
      let formattedNumber = '';
      if (numbers.length > 3) {
        formattedNumber += numbers.substring(0, 3) + '-';
        if (numbers.length > 7) {
          formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
        } else {
          formattedNumber += numbers.substring(3);
        }
      } else {
        formattedNumber = numbers;
      }
      setFormData({ ...formData, phone: formattedNumber });
    }
  };

  const InputField = ({ icon: Icon, label, value, onChange, type = "text", placeholder = "" }) => (
    <div className="space-y-2">
      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
        <Icon className="w-4 h-4 text-gray-500" />
        {label}
      </label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required
        className="w-full px-3 py-2 text-sm transition-colors border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 hover:border-gray-300"
      />
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-md overflow-hidden">
        <div className="flex items-center justify-between p-6 bg-white border-b">
          <div className="flex items-center gap-2">
            <User className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">사용자 정보 수정</h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-6 space-y-4 bg-white">
            <InputField
              icon={Building2}
              label="회사명"
              value={formData.company_name}
              onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
            />
            
            <InputField
              icon={User}
              label="이름"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            
            <InputField
              icon={Phone}
              label="연락처"
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="010-0000-0000"
            />
            
            <InputField
              icon={Briefcase}
              label="직책"
              value={formData.position}
              onChange={(e) => setFormData({ ...formData, position: e.target.value })}
            />
          </div>

          <div className="flex items-center justify-end gap-3 px-6 py-4 border-t bg-gray-50">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 transition-colors bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
            >
              취소
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white transition-colors rounded-lg bg-sky-600 hover:bg-sky-700"
            >
              저장
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
}