import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Card } from "../../ui/card";
import ReservationDetailModal from './ReservationDetailModal';
import { Clock, Users } from 'lucide-react';

const DateCard = ({ date, tours = [], meetings = [], onApprove, onReject }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  
  const groupedReservations = useMemo(() => {
    const grouped = {};
    
    tours?.forEach(tour => {
      const time = tour.available_tour?.tour_time?.time;
      if (!time) return;
      if (!grouped[time]) grouped[time] = { tours: [], meetings: [] };
      grouped[time].tours.push(tour);
    });
    
    meetings?.forEach(meeting => {
      const time = meeting.available_room?.room_time?.time;
      if (!time) return;
      if (!grouped[time]) grouped[time] = { tours: [], meetings: [] };
      grouped[time].meetings.push(meeting);
    });
    
    return grouped;
  }, [tours, meetings]);

  const handleTimeClick = (time, type) => {
    setSelectedTime(time);
    setSelectedType(type);
    setShowModal(true);
  };

  return (
    <Card className="mb-6">
      <div className="p-6">
        <h2 className="mb-4 text-lg font-semibold text-gray-900">
          {format(new Date(date), 'M월 d일 (E)', { locale: ko })}
        </h2>
        
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* 투어 섹션 */}
          <div className="p-4 border rounded-lg bg-sky-50/50 border-sky-100">
            <div className="flex items-center gap-2 mb-3">
              <Clock className="w-4 h-4 text-sky-600" />
              <h3 className="text-base font-medium text-sky-900">VIP 투어</h3>
            </div>
            <div className="space-y-2">
              {Object.entries(groupedReservations).map(([time, { tours }]) => (
                tours.length > 0 && (
                  <button
                    key={`tour-${time}`}
                    onClick={() => handleTimeClick(time, 'tour')}
                    className="flex items-center justify-between w-full p-3 transition-colors bg-white rounded-lg shadow-sm hover:bg-gray-50"
                  >
                    <span className="font-medium text-gray-700">{time}</span>
                    <span className="px-3 py-1 text-sm font-medium rounded-full text-sky-700 bg-sky-100">
                      {tours.length}건
                    </span>
                  </button>
                )
              ))}
            </div>
          </div>

          {/* 미팅룸 섹션 */}
          <div className="p-4 border rounded-lg bg-emerald-50/50 border-emerald-100">
            <div className="flex items-center gap-2 mb-3">
              <Users className="w-4 h-4 text-emerald-600" />
              <h3 className="text-base font-medium text-emerald-900">미팅룸</h3>
            </div>
            <div className="space-y-2">
              {Object.entries(groupedReservations).map(([time, { meetings }]) => (
                meetings.length > 0 && (
                  <button
                    key={`meeting-${time}`}
                    onClick={() => handleTimeClick(time, 'meeting')}
                    className="flex items-center justify-between w-full p-3 transition-colors bg-white rounded-lg shadow-sm hover:bg-gray-50"
                  >
                    <span className="font-medium text-gray-700">{time}</span>
                    <span className="px-3 py-1 text-sm font-medium rounded-full text-emerald-700 bg-emerald-100">
                      {meetings.length}건
                    </span>
                  </button>
                )
              ))}
            </div>
          </div>
        </div>
      </div>

      <ReservationDetailModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        timeSlot={selectedTime}
        reservations={
          selectedTime && selectedType === 'tour'
            ? groupedReservations[selectedTime]?.tours.map(tour => ({ ...tour, type: 'tour' })) || []
            : selectedTime && selectedType === 'meeting'
            ? groupedReservations[selectedTime]?.meetings.map(meeting => ({ ...meeting, type: 'meeting' })) || []
            : []
        }
        onApprove={onApprove}
        onReject={onReject}
      />
    </Card>
  );
};

export default function ReservationList({ dates = [], pendingReservations = {}, onApprove, onReject }) {
  const [selectedDate, setSelectedDate] = useState('all');

  // 예약이 있는 날짜만 필터링
  const datesWithReservations = useMemo(() => {
    return dates.filter(date => {
      const dateReservations = pendingReservations[date.date];
      return (dateReservations?.tours?.length > 0 || dateReservations?.meetings?.length > 0);
    });
  }, [dates, pendingReservations]);

  if (datesWithReservations.length === 0) {
    return (
      <Card className="p-6">
        <div className="py-8 text-center text-gray-500">
          대기 중인 예약이 없습니다.
        </div>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Card className="p-4">
        <div className="flex gap-2 overflow-x-auto">
          <button
            onClick={() => setSelectedDate('all')}
            className={`px-4 py-2 rounded-lg transition-colors duration-200 whitespace-nowrap
              ${selectedDate === 'all'
                ? 'bg-sky-600 text-white shadow-sm'
                : 'text-gray-700 hover:bg-gray-100'}`}
          >
            전체
          </button>
          {datesWithReservations.map(date => (
            <button
              key={date.id}
              onClick={() => setSelectedDate(date.date)}
              className={`px-4 py-2 rounded-lg transition-colors duration-200 whitespace-nowrap
                ${selectedDate === date.date
                  ? 'bg-sky-600 text-white shadow-sm'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              {format(new Date(date.date), 'M월 d일 (E)', { locale: ko })}
            </button>
          ))}
        </div>
      </Card>

      <div className="space-y-6">
        {selectedDate === 'all' ? (
          datesWithReservations.map(date => {
            const dateReservations = pendingReservations[date.date];
            if (!dateReservations) return null;
            
            // 해당 날짜에 투어나 미팅 예약이 하나라도 있는 경우만 렌더링
            if (dateReservations.tours.length === 0 && dateReservations.meetings.length === 0) {
              return null;
            }
            
            return (
              <DateCard
                key={date.id}
                date={date.date}
                tours={dateReservations.tours}
                meetings={dateReservations.meetings}
                onApprove={onApprove}
                onReject={onReject}
              />
            );
          })
        ) : (
          <DateCard
            date={selectedDate}
            tours={pendingReservations[selectedDate]?.tours || []}
            meetings={pendingReservations[selectedDate]?.meetings || []}
            onApprove={onApprove}
            onReject={onReject}
          />
        )}
      </div>
    </div>
  );
}