import { XIcon } from 'lucide-react';

const TimeTableDetailModal = ({ isOpen, onClose, reservation }) => {
 if (!isOpen || !reservation) return null;

 const { 
   visitor, 
   user, 
   type,
   startTime,
   endTime,
   interval
 } = reservation;

 const reservationType = reservation.available_tour ? 'tour' : 'meeting';
 const tourInfo = reservationType === 'tour' ? reservation.available_tour : null;
 const roomInfo = reservationType === 'meeting' ? reservation.available_room : null;

 return (
   <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-500 bg-opacity-75">
     <div className="w-full max-w-xl p-8 bg-white rounded-xl">
       <div className="flex items-center justify-between mb-8">
         <h3 className="text-xl font-bold text-gray-900">
           {reservationType === 'tour' ? 'VIP 투어' : '미팅룸'} 예약 정보
         </h3>
         <button
           onClick={onClose}
           className="text-gray-400 transition-colors hover:text-gray-500"
         >
           <XIcon className="w-6 h-6" />
         </button>
       </div>

       <div className="space-y-8">
         {/* 신청자 정보 */}
         <div>
           <h4 className="pb-3 text-lg font-semibold text-gray-900 border-b">
             신청자 정보
           </h4>
           <dl className="grid grid-cols-2 mt-4 gap-x-6 gap-y-4">
             <div>
               <dt className="text-sm font-medium text-gray-600">이름</dt>
               <dd className="mt-1 text-gray-900">{user?.name}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">회사명</dt>
               <dd className="mt-1 text-gray-900">{user?.company_name}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">직책</dt>
               <dd className="mt-1 text-gray-900">{user?.position}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">연락처</dt>
               <dd className="mt-1 text-gray-900">{user?.phone}</dd>
             </div>
           </dl>
         </div>

         {/* 방문자 정보 */}
         <div>
           <h4 className="pb-3 text-lg font-semibold text-gray-900 border-b">
             방문자 정보
           </h4>
           <dl className="grid grid-cols-2 mt-4 gap-x-6 gap-y-4">
             <div>
               <dt className="text-sm font-medium text-gray-600">이름</dt>
               <dd className="mt-1 text-gray-900">{visitor?.name}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">연락처</dt>
               <dd className="mt-1 text-gray-900">{visitor?.phone}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">회사/기관</dt>
               <dd className="mt-1 text-gray-900">{visitor?.company || visitor?.title}</dd>
             </div>
             <div>
               <dt className="text-sm font-medium text-gray-600">방문 인원</dt>
               <dd className="mt-1 text-gray-900">{visitor?.count}명</dd>
             </div>
           </dl>
         </div>

         {/* 예약 정보 */}
         <div>
           <h4 className="pb-3 text-lg font-semibold text-gray-900 border-b">
             예약 정보
           </h4>
           <dl className="grid grid-cols-2 mt-4 gap-x-6 gap-y-4">
             <div className="col-span-2">
               <dt className="text-sm font-medium text-gray-600">예약 시간</dt>
               <dd className="mt-1 text-gray-900">
                 {startTime && endTime ? (
                   <>
                     {startTime} - {endTime}
                     <span className="ml-2 text-sm text-gray-500">
                       ({interval - 5}분)
                     </span>
                   </>
                 ) : (
                   '시간 정보 없음'
                 )}
               </dd>
             </div>
             {type === 'tour' && tourInfo?.docent && (
               <div className="col-span-2">
                 <dt className="text-sm font-medium text-gray-600">도슨트</dt>
                 <dd className="mt-1 text-gray-900">
                   {tourInfo.docent.name} ({tourInfo.docent.language})
                 </dd>
               </div>
             )}
             {type === 'meeting' && roomInfo?.room && (
               <div className="col-span-2">
                 <dt className="text-sm font-medium text-gray-600">미팅룸</dt>
                 <dd className="mt-1 text-gray-900">
                   {roomInfo.room.name} ({roomInfo.room.size}인실)
                 </dd>
               </div>
             )}
             <div className="col-span-2">
               <dt className="text-sm font-medium text-gray-600">비고</dt>
               <dd className="p-3 mt-1 text-gray-900 rounded-lg bg-gray-50">
                 {reservation.note || '작성된 비고가 없습니다.'}
               </dd>
             </div>
             {type === 'meeting' && reservation.feedback && (
               <div className="col-span-2">
                 <dt className="text-sm font-medium text-gray-600">피드백</dt>
                 <dd className="p-3 mt-1 text-gray-900 rounded-lg bg-gray-50">
                   {reservation.feedback}
                 </dd>
               </div>
             )}
           </dl>
         </div>
       </div>
     </div>
   </div>
 );
};

export default TimeTableDetailModal;