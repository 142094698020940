import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarDays, Clock, Users, Building2 } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import PageHeader from '../../components/user/PageHeader';
import StepProgress from '../../components/user/StepProgress';
import NavigationButtons from '../../components/user/NavigationButtons';
import VisitorForm from '../../components/user/VisitorForm';
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function RoomReservationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.state || {};

  const [step, setStep] = useState(() => {
    if (initialState.date) return 2;
    return 1;
  });

  const [dates, setDates] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialState.date || '');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visitorInfo, setVisitorInfo] = useState({
    title: '',
    name: '',
    phone: '',
    count: 1,
  });
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const [datesResponse, roomsResponse] = await Promise.all([
          api.get('/api/user/event-dates/'),
          api.get('/api/user/rooms/')
        ]);

        setDates(datesResponse.data);
        setRooms(roomsResponse.data);

        if (initialState.roomId) {
          const room = roomsResponse.data.find(
            r => r.id === parseInt(initialState.roomId)
          );
          if (room) {
            setSelectedRoom(room);
            setStep(3);
          }
        }
      } catch (error) {
        toast.error('데이터를 불러오는데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [initialState.roomId]);

  const fetchAvailableRooms = async (date, room) => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/user/available-rooms/', {
        params: {
          date: date,
          room_id: room.id
        }
      });

      const sortedRooms = response.data.sort((a, b) => {
        return a.room_time.time.localeCompare(b.room_time.time);
      });

      setAvailableRooms(sortedRooms);

      if (initialState.timeId) {
        const room = sortedRooms.find(r => r.room_time.id === initialState.timeId);
        if (room) {
          setSelectedTimeSlot(room);
        }
      }
    } catch (error) {
      toast.error('가용 시간을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate && selectedRoom) {
      fetchAvailableRooms(selectedDate, selectedRoom);
    }
  }, [selectedDate, selectedRoom?.id]);

  const canGoNext = () => {
    switch (step) {
      case 1:
        return !!selectedDate;
      case 2:
        return !!selectedRoom;
      case 3:
        return !!selectedTimeSlot;
      case 4:
        return true;
      default:
        return false;
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      if (step === 4) {
        setSelectedTimeSlot(null);
      } else if (step === 3) {
        setSelectedRoom(null);
      } else if (step === 2) {
        setSelectedDate('');
      }
      setStep(prev => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (canGoNext() && step < 4) {
      setStep(prev => prev + 1);
    }
  };

  const validateVisitorInfo = () => {
    if (!visitorInfo.title.trim()) {
      toast.error('미팅 목적을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.name.trim()) {
      toast.error('방문자명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.phone.trim() || visitorInfo.phone.replace(/-/g, '').length !== 11) {
      toast.error('올바른 연락처를 입력해주세요.');
      return false;
    }
    if (visitorInfo.count < 1 || visitorInfo.count > selectedRoom.size) {
      toast.error(`방문 인원은 1명 이상 ${selectedRoom.size}명 이하여야 합니다.`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateVisitorInfo()) return;

    try {
      await api.post('/api/user/reserve-meeting/', {
        available_room_id: selectedTimeSlot.id,
        visitor: visitorInfo,
        note: note.trim()
      });
      
      toast.success('미팅룸 예약이 신청되었습니다.');
      navigate('/dashboard', { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.error || '예약 신청에 실패했습니다.');
    }
  };

  if (isLoading) {
    return (
      <div className="container max-w-md min-h-screen p-4 mx-auto">
        <div className="flex items-center justify-center h-screen">
          <div className="w-6 h-6 border-2 rounded-full border-t-transparent border-sky-600 animate-spin" />
        </div>
      </div>
    );
  }

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CalendarDays className="w-5 h-5" />
                날짜 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-3">
                {dates.map((date) => (
                  <button
                    key={date.id}
                    onClick={() => setSelectedDate(date.date)}
                    className={`flex items-center justify-between w-full p-4 text-left border rounded-lg hover:bg-gray-50 ${
                      selectedDate === date.date ? 'border-sky-500 bg-sky-50' : ''
                    }`}
                  >
                    <span className="font-medium">
                      {new Date(date.date).toLocaleDateString()}
                    </span>
                  </button>
                ))}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Building2 className="w-5 h-5" />
                미팅룸 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedRoom ? selectedRoom.id : ''}
                onChange={(e) => {
                  const room = rooms.find(r => r.id === parseInt(e.target.value));
                  setSelectedRoom(room);
                }}
                className="w-full px-4 py-3 text-lg border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">미팅룸을 선택하세요</option>
                {rooms.map((room) => (
                  <option key={room.id} value={room.id}>
                    {room.name} (최대 {room.size}인)
                  </option>
                ))}
              </select>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 3:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Clock className="w-5 h-5" />
                시간 선택
              </CardTitle>
              <CardDescription>
                {selectedRoom.name} - 최대 {selectedRoom.size}인
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-3">
                {availableRooms.length > 0 ? (
                  availableRooms.map((room) => (
                    <button
                      key={room.id}
                      onClick={() => setSelectedTimeSlot(room)}
                      className={`flex items-center justify-center w-full p-4 text-center border rounded-lg hover:bg-gray-50 ${
                        selectedTimeSlot?.id === room.id ? 'border-sky-500 bg-sky-50' : ''
                      }`}
                    >
                      <span className="font-medium">
                        {formatTime(room.room_time.time)}
                      </span>
                    </button>
                  ))
                ) : (
                  <div className="col-span-2 py-8 text-center text-gray-500">
                    예약 가능한 시간이 없습니다
                  </div>
                )}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

        case 4:
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Users className="w-5 h-5" />
                  방문자 정보
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="p-5 mb-6 border rounded-lg bg-white/50 backdrop-blur-sm">
                  <h3 className="mb-3 text-sm font-medium text-gray-800">예약 정보</h3>
                  <dl className="grid gap-3">
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <CalendarDays className="w-4 h-4" />
                          <span>날짜</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        {new Date(selectedDate).toLocaleDateString()}
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Building2 className="w-4 h-4" />
                          <span>미팅룸</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        <div className="text-right">
                          <div>{selectedRoom.name}</div>
                          <div className="text-xs text-gray-500">최대 {selectedRoom.size}인</div>
                        </div>
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Clock className="w-4 h-4" />
                          <span>시간</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        {formatTime(selectedTimeSlot?.room_time.time)}
                      </dd>
                    </div>
                  </dl>
                </div>
        
                <VisitorForm 
                  visitorInfo={visitorInfo}
                  onChange={setVisitorInfo}
                  type="room"
                  maxCount={selectedRoom?.size}
                />
                <div className="mt-4">
                  <label className="block mb-1 text-sm font-medium">추가 요청사항</label>
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full h-24 px-4 py-3 border rounded-lg resize-none focus:ring-2 focus:ring-sky-500"
                    placeholder="추가 요청사항이 있다면 입력해주세요."
                  />
                </div>
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleSubmit}
                    currentStep={step}
                    isLastStep={true}
                    canGoNext={true}
                  />
                </div>
              </CardContent>
            </Card>
          );

      default:
        return null;
    }
  };

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      <PageHeader 
        title="미팅룸 예약" 
        onBack={handlePrevious}
      />
      <StepProgress currentStep={step} />
      {renderStepContent()}
    </div>
  );
}