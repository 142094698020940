import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, User, Building2, Phone, BriefcaseIcon, Key } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import api from '../../api/axios';
import { toast } from 'react-hot-toast';

export default function ProfilePage() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [stats, setStats] = useState({
    totalReservations: 0,
    pendingReservations: 0,
    upcomingReservations: 0,
  });

  useEffect(() => {
    fetchUserInfo();
    fetchReservationStats();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get('/api/user/my-info/');
      setUserInfo(response.data);
    } catch (error) {
      toast.error('사용자 정보를 불러오는데 실패했습니다.');
    }
  };

  const fetchReservationStats = async () => {
    try {
      const response = await api.get('/api/user/my-reservations/');
      const { tours, meetings } = response.data;
      const allReservations = [...tours, ...meetings];
      
      const now = new Date();
  
      // 전체 예약 수
      const totalCount = allReservations.length;
  
      // 상태별 카운트
      const pendingCount = allReservations.filter(
        r => r.status === 'PENDING'
      ).length;
  
      const cancelledCount = allReservations.filter(
        r => r.status === 'CANCELLED'
      ).length;
  
      // 예정된 예약 (승인됨 + 아직 지나지 않은 날짜)
      const upcomingCount = allReservations.filter(r => {
        if (r.status !== 'APPROVED') return false;
        
        const eventDate = new Date(
          r.available_tour 
            ? r.available_tour.event_date.date 
            : r.available_room.event_date.date
        );
        
        // 시간까지 비교
        const eventTime = r.available_tour 
          ? r.available_tour.tour_time.time 
          : r.available_room.room_time.time;
        const [hours, minutes] = eventTime.split(':');
        eventDate.setHours(parseInt(hours), parseInt(minutes));
        
        return eventDate > now;
      }).length;
  
      setStats({
        totalReservations: totalCount,
        cancelledReservations: cancelledCount,
        pendingReservations: pendingCount,
        upcomingReservations: upcomingCount,
      });
    } catch (error) {
      console.error('Failed to fetch reservation stats:', error);
    }
  };

  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
  
    try {
      await api.post('/api/user/logout/');
      sessionStorage.clear();
      navigate('/', { replace: true });
    } catch (error) {
      toast.error('로그아웃에 실패했습니다.');
      // 에러가 발생하더라도 로컬의 세션은 클리어하고 로그인 페이지로 이동
      sessionStorage.clear();
      navigate('/', { replace: true });
    }
  };

  if (!userInfo) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-6 h-6 border-2 rounded-full border-sky-600 animate-spin border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      {/* Header */}
      <header className="flex items-center mb-6">
        <button
          onClick={() => navigate(-1)}
          className="p-2 mr-2 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-bold">내 정보</h1>
      </header>

      {/* Profile Summary */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <User className="w-5 h-5" />
            프로필
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="divide-y">
            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">이름</div>
                <div className="mt-1 font-medium">{userInfo.name}</div>
              </div>
              <User className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">회사</div>
                <div className="mt-1 font-medium">{userInfo.company_name}</div>
              </div>
              <Building2 className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">직책</div>
                <div className="mt-1 font-medium">{userInfo.position}</div>
              </div>
              <BriefcaseIcon className="w-5 h-5 text-gray-400" />
            </div>

            <div className="flex items-start justify-between py-3">
              <div>
                <div className="text-sm text-gray-500">연락처</div>
                <div className="mt-1 font-medium">{userInfo.phone}</div>
              </div>
              <Phone className="w-5 h-5 text-gray-400" />
            </div>

            <button
              onClick={() => setShowQRCode(true)}
              className="flex items-start justify-between w-full py-3"
            >
              <div>
                <div className="text-sm text-gray-500">발급 코드</div>
                <div className="mt-1 font-medium">{userInfo.code}</div>
              </div>
              <Key className="w-5 h-5 text-gray-400" />
            </button>
          </div>
        </CardContent>
      </Card>

      {/* Reservation Stats */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>예약 현황</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-4 gap-4">
            <div className="text-center">
              <div className="text-2xl font-bold text-sky-600">
                {stats.totalReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">전체</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-gray-500">
                {stats.cancelledReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">취소</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-yellow-500">
                {stats.pendingReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">대기</div>
            </div>
            <div className="text-center">
              <div className="text-2xl font-bold text-green-500">
                {stats.upcomingReservations}
              </div>
              <div className="mt-1 text-sm text-gray-500">예정</div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Logout Button */}
      <button
        onClick={handleLogout}
        className="w-full py-3 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
      >
        로그아웃
      </button>

      {/* QR Code Dialog */}
      <Dialog open={showQRCode} onOpenChange={setShowQRCode}>
        <DialogContent className="text-center">
          <DialogHeader>
            <DialogTitle>발급 코드 QR</DialogTitle>
          </DialogHeader>
          <div className="p-4">
            <div className="w-48 h-48 mx-auto mb-4 bg-gray-100">
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${userInfo.code}`}
                alt="QR Code"
                className="w-full h-full"
              />
            </div>
            <div className="text-lg font-bold">{userInfo.code}</div>
            <div className="mt-2 text-sm text-gray-500">
              이 코드로 다시 로그인할 수 있습니다
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}