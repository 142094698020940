import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/axios';
import backgroundImage from '../../assets/background.png';
import logoImage from '../../assets/logo.png';

export default function AdminLoginPage() {
  const [credentials, setCredentials] = useState({ name: '', password: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await api.post('/api/admin/login/', credentials);
      if (response.data.message === 'Login successful') {
        navigate('/admin/dashboard');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response) {
        // 서버에서 응답이 왔지만 에러인 경우
        setError(err.response.data.error || '로그인에 실패했습니다.');
      } else if (err.request) {
        // 요청은 보냈지만 응답을 받지 못한 경우
        setError('서버와 통신할 수 없습니다. 네트워크를 확인해주세요.');
      } else {
        // 요청 설정 중에 문제가 발생한 경우
        setError('로그인 처리 중 오류가 발생했습니다.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full min-h-screen bg-white">
      <main className="flex justify-center w-full h-full min-h-screen">
        {/* 배경 이미지 - 모바일에서는 숨김 */}
        <img 
          src={backgroundImage} 
          alt='bg' 
          className='fixed top-0 bottom-0 left-0 right-0 hidden object-cover w-full h-full lg:block'
        />
        
        {/* 왼쪽 로고 섹션 - 모바일에서는 숨김 */}
        <div className='flex-col justify-center hidden w-full h-full max-w-2xl min-h-screen lg:flex'>
          <img 
            src={logoImage} 
            alt='logo' 
            className='object-center w-auto h-40 mx-auto'
          />
        </div>

        {/* 로그인 폼 섹션 */}
        <div className="z-50 w-full h-full max-w-2xl min-h-screen bg-white lg:shadow-xl">
          <div className="flex items-center justify-center w-full h-full min-h-screen p-6 lg:p-8 lg:ml-5">
            <div className="w-full max-w-md space-y-8">
              {/* 로고 - 모든 화면에서 표시 */}
              <img 
                src={logoImage} 
                alt='logo' 
                className='object-center w-auto h-32 mx-auto mb-6'
              />
              
              <h1 className="space-y-1 text-4xl font-bold text-center text-sky-700">
                <div>CES 2025 SK</div>
                <div>관리자 센터</div>
              </h1>

              {error && (
                <div className="px-4 py-3 text-red-700 border border-red-400 rounded-lg bg-red-50">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <input
                    id="name"
                    type="text"
                    value={credentials.name}
                    onChange={(e) => setCredentials({ ...credentials, name: e.target.value })}
                    placeholder="아이디"
                    className="block w-full px-4 py-3 text-lg border border-gray-300 rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                    disabled={isLoading}
                  />
                </div>

                <div>
                  <input
                    id="password"
                    type="password"
                    value={credentials.password}
                    onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                    placeholder="비밀번호"
                    className="block w-full px-4 py-3 text-lg border border-gray-300 rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                    disabled={isLoading}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full flex justify-center px-4 py-3 text-lg font-medium text-white rounded-lg transition-colors ${
                    isLoading 
                      ? 'bg-blue-400 cursor-not-allowed' 
                      : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  }`}
                >
                  {isLoading ? '로그인 중...' : '로그인'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}