export default function VisitorForm({ 
    visitorInfo, 
    onChange, 
    type = 'tour' // 'tour' or 'room'
  }) {
    const handlePhoneChange = (e) => {
      let value = e.target.value.replace(/[^\d-]/g, '');
      const numbers = value.replace(/-/g, '');
      
      if (numbers.length <= 11) {
        let formattedNumber = '';
        if (numbers.length > 3) {
          formattedNumber += numbers.substring(0, 3) + '-';
          if (numbers.length > 7) {
            formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
          } else {
            formattedNumber += numbers.substring(3);
          }
        } else {
          formattedNumber = numbers;
        }
        onChange({ ...visitorInfo, phone: formattedNumber });
      }
    };
  
    return (
      <div className="space-y-4">
        {type === 'room' && (
          <div>
            <label className="block mb-1 text-sm font-medium">미팅 목적</label>
            <input
              type="text"
              value={visitorInfo.title || ''}
              onChange={(e) => onChange({ ...visitorInfo, title: e.target.value })}
              className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
        )}
        {type === 'tour' && (
          <div>
            <label className="block mb-1 text-sm font-medium">회사명</label>
            <input
              type="text"
              value={visitorInfo.company || ''}
              onChange={(e) => onChange({ ...visitorInfo, company: e.target.value })}
              className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
        )}
        <div>
          <label className="block mb-1 text-sm font-medium">방문자명</label>
          <input
            type="text"
            value={visitorInfo.name}
            onChange={(e) => onChange({ ...visitorInfo, name: e.target.value })}
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium">연락처</label>
          <input
            type="text"
            value={visitorInfo.phone}
            onChange={handlePhoneChange}
            placeholder="010-0000-0000"
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium">방문 인원</label>
          <input
            type="number"
            min="1"
            value={visitorInfo.count}
            onChange={(e) => onChange({ ...visitorInfo, count: parseInt(e.target.value) })}
            className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-sky-500"
            required
          />
        </div>
      </div>
    );
  }