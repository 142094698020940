import { XIcon, User, Users, Clock, Building2, ScrollText } from 'lucide-react';
import { Card } from '../../ui/card';

const ReservationStatus = ({ status }) => {
  const getStatusStyle = () => {
    switch (status) {
      case 'PENDING':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-200';
      case 'APPROVED':
        return 'bg-emerald-100 text-emerald-800 border border-emerald-200';
      case 'REJECTED':
        return 'bg-red-100 text-red-800 border border-red-200';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-200';
    }
  };

  const getStatusText = () => {
    switch (status) {
      case 'PENDING':
        return '대기중';
      case 'APPROVED':
        return '승인됨';
      case 'REJECTED':
        return '거절됨';
      default:
        return status;
    }
  };

  return (
    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle()}`}>
      {getStatusText()}
    </span>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-start gap-3">
    <div className="mt-1">
      <Icon className="w-4 h-4 text-gray-500" />
    </div>
    <div>
      <div className="text-sm text-gray-500">{label}</div>
      <div className="font-medium text-gray-900">{value || '-'}</div>
    </div>
  </div>
);

const ReservationDetailModal = ({ isOpen, onClose, timeSlot, reservations, onApprove, onReject }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-3xl max-h-[80vh] bg-white overflow-hidden">
        <div className="flex items-center justify-between p-6 bg-white border-b">
          <div className="flex items-center gap-2">
            <Clock className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">
              {timeSlot} 예약 현황
            </h3>
          </div>
          <button 
            onClick={onClose} 
            className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
          >
            <XIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="p-6 space-y-4 overflow-y-auto bg-white">
          {reservations.map(reservation => (
            <Card key={reservation.id} className="p-6 bg-white border shadow-sm">
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center gap-2">
                  <span className={`text-sm font-medium px-3 py-1 rounded-full
                    ${reservation.type === 'tour' 
                      ? 'bg-sky-100 text-sky-700' 
                      : 'bg-emerald-100 text-emerald-700'}`}
                  >
                    {reservation.type === 'tour' ? 'VIP 투어' : '미팅룸'}
                  </span>
                  <ReservationStatus status={reservation.status} />
                </div>
              </div>

              <div className="grid gap-6 mb-6 md:grid-cols-2">
                
                {/* 신청자 정보 */}
                <div className="p-4 bg-white border rounded-lg">
                  <h4 className="mb-4 text-sm font-semibold text-gray-900">신청자 정보</h4>
                  <div className="space-y-4">
                    <InfoItem 
                      icon={User} 
                      label="이름" 
                      value={reservation.user.name} 
                    />
                    <InfoItem 
                      icon={Building2} 
                      label="소속" 
                      value={reservation.user.position} 
                    />
                    <InfoItem 
                      icon={User} 
                      label="연락처" 
                      value={reservation.user.phone} 
                    />
                  </div>
                </div>

                {/* 방문자 정보 */}
                <div className="p-4 bg-white border rounded-lg">
                  <h4 className="mb-4 text-sm font-semibold text-gray-900">방문자 정보</h4>
                  <div className="space-y-4">
                    <InfoItem 
                      icon={User} 
                      label="방문자" 
                      value={`${reservation.visitor.name} (${reservation.visitor.count}명)`} 
                    />
                    <InfoItem 
                      icon={Building2} 
                      label="회사/기관" 
                      value={reservation.visitor.company || reservation.visitor.title} 
                    />
                    {reservation.type === 'tour' ? (
                      <InfoItem 
                        icon={Users} 
                        label="도슨트" 
                        value={reservation.available_tour.docent.name} 
                      />
                    ) : (
                      <InfoItem 
                        icon={Building2} 
                        label="미팅룸" 
                        value={reservation.available_room.room.name} 
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* 비고 */}
              {reservation.note && (
                <div className="p-4 mb-4 bg-white border rounded-lg">
                  <div className="flex items-start gap-3">
                    <ScrollText className="w-4 h-4 mt-1 text-gray-500" />
                    <div>
                      <div className="text-sm text-gray-500">비고</div>
                      <div className="text-gray-900">{reservation.note}</div>
                    </div>
                  </div>
                </div>
              )}

              {/* 승인/거절 버튼 */}
              {reservation.status === 'PENDING' && (
                <div className="flex justify-end gap-2 mt-6">
                  <button
                    onClick={() => onApprove(reservation)}
                    className="px-4 py-2 text-sm font-medium text-white transition-colors rounded-lg bg-emerald-600 hover:bg-emerald-700"
                  >
                    승인
                  </button>
                  <button
                    onClick={() => onReject(reservation)}
                    className="px-4 py-2 text-sm font-medium text-white transition-colors bg-red-600 rounded-lg hover:bg-red-700"
                  >
                    거절
                  </button>
                </div>
              )}
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default ReservationDetailModal;