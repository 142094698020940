export default function StepProgress({ currentStep, totalSteps = 4 }) {
    return (
      <div className="flex mb-6 overflow-hidden rounded-full bg-gray-50">
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`flex-1 h-2 ${
              currentStep >= index + 1 ? 'bg-sky-600' : 'bg-gray-200'
            }`}
          />
        ))}
      </div>
    );
  }