import { X, User, Users, Calendar, MapPin, Clock, FileText, Building2 } from 'lucide-react';
import { Card } from '../../ui/card';

const InfoItem = ({ icon: Icon, label, value }) => {
  if (!value) return null;
  
  return (
    <div className="flex items-start gap-3">
      <div className="mt-1">
        <Icon className="w-4 h-4 text-gray-500" />
      </div>
      <div>
        <div className="text-sm text-gray-500">{label}</div>
        <div className="font-medium text-gray-900">{value}</div>
      </div>
    </div>
  );
};

const ReservationDetailModal = ({ isOpen, onClose, reservation }) => {
  if (!isOpen || !reservation) return null;
  
  const isTour = 'available_tour' in reservation;
  
  const reservationInfo = {
    type: isTour ? 'VIP 투어' : '미팅룸',
    time: isTour 
      ? reservation.available_tour.tour_time.time
      : reservation.available_room.room_time.time,
    date: isTour
      ? reservation.available_tour.event_date.date
      : reservation.available_room.event_date.date,
    location: isTour
      ? `도슨트: ${reservation.available_tour.docent.name} (${reservation.available_tour.docent.language})`
      : `회의실: ${reservation.available_room.room.name} (${reservation.available_room.room.size}인실)`,
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-900/80">
      <Card className="w-full max-w-2xl overflow-hidden bg-white">
        {/* 헤더 */}
        <div className="flex items-center justify-between p-6 border-b">
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-sky-600" />
            <h3 className="text-lg font-medium text-gray-900">예약 상세 정보</h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 transition-colors rounded-full hover:text-gray-500 hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* 본문 */}
        <div className="p-6 space-y-6">
          {/* 예약 기본 정보 */}
          <Card className="p-4 bg-sky-50 border-sky-100">
            <div className="grid grid-cols-2 gap-4">
              <InfoItem 
                icon={Calendar} 
                label="예약 일자" 
                value={reservationInfo.date} 
              />
              <InfoItem 
                icon={Clock} 
                label="예약 시간" 
                value={reservationInfo.time} 
              />
              <div className="col-span-2">
                <InfoItem 
                  icon={MapPin} 
                  label={isTour ? '도슨트 정보' : '장소 정보'} 
                  value={reservationInfo.location} 
                />
              </div>
            </div>
          </Card>

          {/* 신청자 및 방문자 정보 */}
          <div className="grid gap-6 md:grid-cols-2">
            {/* 신청자 정보 */}
            <div className="space-y-4">
              <h4 className="text-sm font-semibold text-gray-900">신청자 정보</h4>
              <Card className="p-4 space-y-4 border">
                <InfoItem 
                  icon={User} 
                  label="이름" 
                  value={reservation.user.name} 
                />
                <InfoItem 
                  icon={Building2} 
                  label="회사명" 
                  value={reservation.user.company_name} 
                />
                <InfoItem 
                  icon={User} 
                  label="직책" 
                  value={reservation.user.position} 
                />
                <InfoItem 
                  icon={User} 
                  label="연락처" 
                  value={reservation.user.phone} 
                />
              </Card>
            </div>

            {/* 방문자 정보 */}
            <div className="space-y-4">
              <h4 className="text-sm font-semibold text-gray-900">방문자 정보</h4>
              <Card className="p-4 space-y-4 border">
                <InfoItem 
                  icon={User} 
                  label="이름" 
                  value={reservation.visitor.name} 
                />
                <InfoItem 
                  icon={Building2} 
                  label="소속" 
                  value={reservation.visitor.company || reservation.visitor.title} 
                />
                <InfoItem 
                  icon={User} 
                  label="연락처" 
                  value={reservation.visitor.phone} 
                />
                <InfoItem 
                  icon={Users} 
                  label="방문 인원" 
                  value={`${reservation.visitor.count}명`} 
                />
              </Card>
            </div>
          </div>

          {/* 비고 및 추가 정보 */}
          {(reservation.note || reservation.feedback) && (
            <div className="space-y-4">
              <h4 className="text-sm font-semibold text-gray-900">추가 정보</h4>
              <Card className="p-4 space-y-4 border">
                {reservation.note && (
                  <InfoItem 
                    icon={FileText} 
                    label="비고" 
                    value={reservation.note} 
                  />
                )}
                {reservation.feedback && (
                  <InfoItem 
                    icon={FileText} 
                    label="피드백" 
                    value={reservation.feedback} 
                  />
                )}
              </Card>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ReservationDetailModal;