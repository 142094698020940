import React, { useState, useMemo } from 'react';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import { Search, RefreshCw } from 'lucide-react';
import AdminHeader from '../../components/admin/layout/AdminHeader';
import ReservationDetailModal from '../../components/admin/dashboard/ReservationDetailModal';

const AdminDashboardPage = () => {
  const {
    dates,
    docents,
    rooms,
    approvedReservations,
    loading,
    refreshData
  } = useAdminDashboard();

  const [view, setView] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReservation, setSelectedReservation] = useState(null);

  // 시간대 (9시부터 18시까지)
  const timeSlots = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => {
      const hour = 9 + i;
      return `${hour.toString().padStart(2, '0')}:00`;
    });
  }, []);

  // 예약 시간에 따라 해당하는 시간대 컬럼을 찾는 함수
  const getTimeSlotForReservation = (time) => {
    const hour = parseInt(time.split(':')[0]);
    return `${hour.toString().padStart(2, '0')}:00`;
  };

  const filteredReservations = useMemo(() => {
    if (!searchTerm) return approvedReservations;

    const filtered = {};
    Object.entries(approvedReservations).forEach(([date, dayData]) => {
      filtered[date] = {
        tours: dayData.tours.filter(tour =>
          tour.visitor.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tour.visitor.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        meetings: dayData.meetings.filter(meeting =>
          meeting.visitor.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          meeting.visitor.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      };
    });
    return filtered;
  }, [approvedReservations, searchTerm]);

  const getReservationCard = (reservation, type) => {
    const time = type === 'tour' 
      ? reservation.available_tour.tour_time.time
      : reservation.available_room.room_time.time;
    const visitor = reservation.visitor;
    const user = reservation.user;

    return (
      <button
        onClick={() => setSelectedReservation(reservation)}
        className="w-full px-2 py-1 mb-1 text-center transition-colors border rounded border-sky-300 bg-sky-50 hover:bg-sky-100"
      >
        <div className="text-xs font-medium text-sky-700">[{time}]</div>
        <div className="text-sm font-medium">
          {type === 'tour' ? visitor.company : visitor.title}
        </div>
        <div className="text-xs text-gray-600">
          {visitor.name} ({visitor.count}명) / {user.name}
        </div>
      </button>
    );
  };

  const EmptySlot = () => (
    <div className="text-xs text-center text-gray-400">예약 없음</div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminHeader />
      
      <main className="max-w-[99%] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* 컨트롤 패널 */}
        <div className="sticky top-0 z-10 flex items-center justify-between p-4 mb-6 bg-white rounded-lg shadow-sm">
          <select
            className="border rounded px-3 py-1.5 text-sm"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
            <option value="all">전체 보기</option>
            <option value="tour">VIP 투어</option>
            <option value="meeting">미팅룸</option>
          </select>

          <div className="flex items-center space-x-2">
            <div className="relative">
              <input
                type="text"
                placeholder="검색..."
                className="border rounded pl-8 pr-3 py-1.5 text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute w-4 h-4 text-gray-400 transform -translate-y-1/2 left-2 top-1/2" />
            </div>
            <button
              onClick={refreshData}
              className="p-1.5 rounded hover:bg-gray-100"
            >
              <RefreshCw className="w-4 h-4" />
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="w-8 h-8 border-b-2 border-gray-900 rounded-full animate-spin"></div>
          </div>
        ) : (
          <div className="space-y-8">
            {dates.map(date => (
              <div key={date.date} className="p-6 bg-white rounded-lg shadow-sm">
                <h2 className="mb-4 text-xl font-medium text-sky-600">{date.date}</h2>
                <div className="mb-6 border-b-2 border-gray-300"></div>
                
                {/* VIP 투어 섹션 */}
                {(view === 'all' || view === 'tour') && (
                  <div className="mb-8">
                    <h3 className="mb-3 text-base font-medium">VIP 투어</h3>
                    <div className="overflow-x-auto border rounded">
                      <div className="grid grid-cols-[120px_repeat(10,minmax(100px,1fr))]">
                        {/* 헤더 */}
                        <div className="p-2 text-sm font-medium text-center border-b bg-gray-50">분류</div>
                        {timeSlots.map(time => (
                          <div key={time} className="p-2 text-sm font-medium text-center border-b bg-gray-50">
                            {time}
                          </div>
                        ))}
                        
                        {/* 도슨트별 행 */}
                        {docents.map(docent => (
                          <React.Fragment key={docent.id}>
                            <div className="p-2 text-center border-b">
                              <div className="text-sm font-medium">{docent.name}</div>
                              <div className="text-xs text-gray-500">{docent.language}</div>
                            </div>
                            {timeSlots.map(timeSlot => {
                              const reservations = filteredReservations[date.date]?.tours.filter(tour => 
                                getTimeSlotForReservation(tour.available_tour.tour_time.time) === timeSlot &&
                                tour.available_tour.docent.id === docent.id
                              ) || [];
                              return (
                                <div key={timeSlot} className="p-1.5 border-b">
                                  {reservations.length > 0 
                                    ? reservations.map(reservation => getReservationCard(reservation, 'tour'))
                                    : <EmptySlot />
                                  }
                                </div>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* 미팅룸 섹션 */}
                {(view === 'all' || view === 'meeting') && (
                  <div>
                    <h3 className="mb-3 text-base font-medium">미팅룸</h3>
                    <div className="overflow-x-auto border rounded">
                      <div className="grid grid-cols-[120px_repeat(10,minmax(100px,1fr))]">
                        {/* 헤더 */}
                        <div className="p-2 text-sm font-medium text-center border-b bg-gray-50">회의실</div>
                        {timeSlots.map(time => (
                          <div key={time} className="p-2 text-sm font-medium text-center border-b bg-gray-50">
                            {time}
                          </div>
                        ))}
                        
                        {/* 회의실별 행 */}
                        {rooms.map(room => (
                          <React.Fragment key={room.id}>
                            <div className="p-2 text-center border-b">
                              <div className="text-sm font-medium">{room.name}</div>
                              <div className="text-xs text-gray-500">수용 인원: {room.size}명</div>
                            </div>
                            {timeSlots.map(timeSlot => {
                              const reservations = filteredReservations[date.date]?.meetings.filter(meeting => 
                                getTimeSlotForReservation(meeting.available_room.room_time.time) === timeSlot &&
                                meeting.available_room.room.id === room.id
                              ) || [];
                              return (
                                <div key={timeSlot} className="p-1.5 border-b">
                                  {reservations.length > 0 
                                    ? reservations.map(reservation => getReservationCard(reservation, 'meeting'))
                                    : <EmptySlot />
                                  }
                                </div>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* 예약 상세 정보 모달 */}
        <ReservationDetailModal
          isOpen={!!selectedReservation}
          onClose={() => setSelectedReservation(null)}
          reservation={selectedReservation}
        />
      </main>
    </div>
  );
};

export default AdminDashboardPage;