import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/axios';
import backgroundImage from '../../assets/background.png';
import logoImage from '../../assets/logo.png';

export default function LoginPage() {
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await api.post('/api/user/login/', {
        code,
        phone
      });
  
      if (response.data.user_data) {
        // 로그인 성공 시 세션 스토리지에 사용자 데이터 저장
        sessionStorage.setItem('user_id', response.data.user_data.id);
        sessionStorage.setItem('user_data', JSON.stringify(response.data.user_data));
        navigate('/dashboard', { state: { fromLogin: true } });
      }
    } catch (error) {
      setError(error.response?.data?.error || '로그인에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[^\d-]/g, '');
    const numbers = value.replace(/-/g, '');
    
    if (numbers.length <= 11) {
      let formattedNumber = '';
      if (numbers.length > 3) {
        formattedNumber += numbers.substring(0, 3) + '-';
        if (numbers.length > 7) {
          formattedNumber += numbers.substring(3, 7) + '-' + numbers.substring(7);
        } else {
          formattedNumber += numbers.substring(3);
        }
      } else {
        formattedNumber = numbers;
      }
      setPhone(formattedNumber);
    }
  };

  return (
    <div className="h-full min-h-screen bg-white">
      <main className="flex justify-center w-full h-full min-h-screen">
        {/* 배경 이미지 - 모바일에서는 숨김 */}
        <img 
          src={backgroundImage} 
          alt='bg' 
          className='fixed top-0 bottom-0 left-0 right-0 hidden object-cover w-full h-full lg:block'
        />
        
        {/* 왼쪽 로고 섹션 - 모바일에서는 숨김 */}
        <div className='flex-col justify-center hidden w-full h-full max-w-2xl min-h-screen lg:flex'>
          <img 
            src={logoImage} 
            alt='logo' 
            className='object-center w-auto h-40 mx-auto'
          />
        </div>

        {/* 로그인 폼 섹션 */}
        <div className="z-50 w-full h-full max-w-2xl min-h-screen bg-white lg:shadow-xl">
          <div className="flex items-center justify-center w-full h-full min-h-screen p-6 lg:p-8 lg:ml-5">
            <form onSubmit={handleSubmit} className="w-full max-w-md space-y-8">
              {/* 로고 - 모든 화면에서 표시 */}
              <img 
                src={logoImage} 
                alt='logo' 
                className='object-center w-auto h-32 mx-auto mb-6'
              />
              
              <h1 className="space-y-1 text-4xl font-bold text-center text-sky-700">
                <div>CES 2025 SK</div>
                <div>예약 서비스</div>
              </h1>

              <div className="space-y-6">
                <div>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value.toUpperCase())}
                    placeholder="발급 코드"
                    className="block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <div>
                  <input
                    type="text"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="휴대폰 번호 11자리"
                    className="block w-full px-4 py-3 text-lg border rounded-lg bg-zinc-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-3 text-lg font-medium text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  로그인
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
}