import { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Card } from '../../ui/card';
import { Calendar, Clock, Users } from 'lucide-react';
import TimeTableDetailModal from './TimeTableDetailModal';

// 상수 정의
const HEADER_HEIGHT = 72;

// 유틸리티 함수들
const parseTime = (timeString) => {
  if (!timeString) return 0;
  try {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  } catch (e) {
    console.error('Invalid time format:', timeString);
    return 0;
  }
};

const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

// 시간 간격 계산 로직
const calculateTimeInterval = (timeSlots) => {
  if (!Array.isArray(timeSlots) || timeSlots.length < 2) return 30;
  const times = timeSlots.map(slot => parseTime(slot.time));
  const intervals = [];
  for (let i = 1; i < times.length; i++) {
    intervals.push(times[i] - times[i - 1]);
  }
  return Math.min(...intervals);
};

// 셀 높이 계산 로직
const calculateCellHeight = () => {
  return 80; // 시간축의 한 칸 높이와 동일하게 설정 (h-20)
};

// 시간 위치 계산
const getTimePosition = (time, startTime, timeSlots) => {
  if (!Array.isArray(timeSlots)) return 0;
  // 해당 시간이 있는 슬롯의 인덱스를 찾아서 위치 계산
  const slotIndex = timeSlots.findIndex(slot => slot.time === time);
  if (slotIndex === -1) return 0;
  
  return (slotIndex * 80) + HEADER_HEIGHT; // 헤더 높이를 여기서 더해줌
};

// 다음 시간 슬롯 찾기
const findNextTimeSlot = (currentTime, timeSlots) => {
  if (!Array.isArray(timeSlots)) return null;
  const currentMinutes = parseTime(currentTime);
  const nextSlot = timeSlots.find(slot => parseTime(slot.time) > currentMinutes);
  return nextSlot ? nextSlot.time : formatTime(currentMinutes + calculateTimeInterval(timeSlots));
};

// 종료 시간 계산
const calculateEndTime = (startTime, timeSlots) => {
  if (!startTime || !Array.isArray(timeSlots)) return null;
  return findNextTimeSlot(startTime, timeSlots);
};

// 시간 높이 계산
const getTimeHeight = (startTime, endTime, gridStartTime, timeSlots) => {
  if (!Array.isArray(timeSlots)) return calculateCellHeight();
  if (!startTime || !endTime) return calculateCellHeight();
  const startPosition = getTimePosition(startTime, gridStartTime, timeSlots);
  const endPosition = getTimePosition(endTime, gridStartTime, timeSlots);
  return endPosition - startPosition;
};

// 예약 카드 컴포넌트
const ReservationCell = ({ 
  reservation, 
  gridStartTime,
  timeSlots,
  onShowDetail,
  type
}) => {
  if (!reservation?.startTime) return null;

  const endTime = calculateEndTime(reservation.startTime, timeSlots);
  const startPosition = getTimePosition(reservation.startTime, gridStartTime, timeSlots);
  const height = getTimeHeight(reservation.startTime, endTime, gridStartTime, timeSlots);
  const isCompact = height < 60;

  const getBgColor = () => {
    if (type === 'tour') return 'bg-sky-50 border-sky-200 hover:bg-sky-100';
    return 'bg-emerald-50 border-emerald-200 hover:bg-emerald-100';
  };

  const getTextColor = () => {
    if (type === 'tour') return 'text-sky-900';
    return 'text-emerald-900';
  };

  return (
    <button 
      onClick={() => onShowDetail({
        ...reservation,
        startTime: reservation.startTime,
        endTime,
      })}
      className={`absolute px-3 transition-all border rounded-lg shadow-sm cursor-pointer left-2 right-2 overflow-hidden ${getBgColor()}`}
      style={{
        top: `${startPosition+9}px`,
        height: `${Math.max(height, 40)}px`,
        paddingTop: isCompact ? '2px' : '8px',
        paddingBottom: isCompact ? '2px' : '8px'
      }}
    >
      {isCompact ? (
        <div className="flex items-center justify-between">
          <span className={`text-xs font-medium truncate ${getTextColor()}`}>
            {reservation.visitor.name} ({reservation.visitor.count}명)
          </span>
          <span className="text-xs text-gray-600">
            {reservation.startTime}
          </span>
        </div>
      ) : (
        <>
          <div className={`text-sm font-medium truncate ${getTextColor()}`}>
            {reservation.visitor.company || reservation.visitor.title}
          </div>
          <div className="mt-1 text-xs text-gray-600">
            {reservation.startTime} - {endTime}
          </div>
          <div className="mt-1 text-sm text-gray-700 truncate">
            {reservation.visitor.name} ({reservation.visitor.count}명)
          </div>
        </>
      )}
    </button>
  );
};

// 타임라인 컬럼 컴포넌트
const TimelineColumn = ({ 
  type,
  reservations = [], 
  gridStartTime,
  timeSlots,
  onShowDetail 
}) => {
  const sortedReservations = useMemo(() => {
    return [...reservations].sort((a, b) => {
      const timeA = type === 'tour'
        ? a.available_tour?.tour_time?.time
        : a.available_room?.room_time?.time;
      const timeB = type === 'tour'
        ? b.available_tour?.tour_time?.time
        : b.available_room?.room_time?.time;
      return parseTime(timeA) - parseTime(timeB);
    });
  }, [reservations, type]);

  return (
    <div className="flex-1 min-w-[200px] border-l first:border-l-0">
      <div className="relative">
        {sortedReservations.map(reservation => {
          const startTime = type === 'tour'
            ? reservation.available_tour?.tour_time?.time
            : reservation.available_room?.room_time?.time;

          if (!startTime) return null;

          return (
            <ReservationCell
              key={reservation.id}
              type={type}
              reservation={{
                ...reservation,
                startTime
              }}
              gridStartTime={gridStartTime}
              timeSlots={timeSlots}
              onShowDetail={onShowDetail}
            />
          );
        })}
      </div>
    </div>
  );
};

// 타임테이블 그리드 컴포넌트
const TimetableGrid = ({
  type,
  timeSlots,
  items,
  selectedDate,
  reservations = [],
  onShowDetail
}) => {

  const [currentTimePosition, setCurrentTimePosition] = useState(null);

  const { startTime, endTime } = useMemo(() => {
    if (!Array.isArray(timeSlots) || !timeSlots.length) {
      return { startTime: '09:00', endTime: '18:00' };
    }
    const times = timeSlots.map(slot => parseTime(slot.time));
    const earliestTime = Math.min(...times);
    const latestTime = Math.max(...times);
    return {
      startTime: formatTime(earliestTime),
      endTime: formatTime(latestTime + calculateTimeInterval(timeSlots))
    };
  }, [timeSlots]);

  const calculateCurrentTimePosition = () => {
    const now = new Date();
    const currentTime = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
    // if (format(now, 'yyyy-MM-dd') !== selectedDate) return null;

    const currentMinutes = parseTime(currentTime);
    const startMinutes = parseTime(startTime);
    const endMinutes = parseTime(endTime);

    if (currentMinutes < startMinutes || currentMinutes > endMinutes) return null;

    const timePosition = (currentMinutes - startMinutes) / calculateTimeInterval(timeSlots) * calculateCellHeight();
    return timePosition + HEADER_HEIGHT;
  };

  useEffect(() => {
    const updatePosition = () => {
      setCurrentTimePosition(calculateCurrentTimePosition());
    };

    // 최초 계산 및 1분마다 업데이트
    updatePosition();
    const interval = setInterval(updatePosition, 60000); // 1분마다 실행

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, [selectedDate, timeSlots, startTime, endTime]);

  return (
    <Card className="overflow-hidden">
      <div className="flex">
        {/* 시간 눈금 */}
        <div className="relative w-16 border-r bg-gray-50/50">
          <div className="sticky top-0 z-10 h-[72px] bg-gray-50/50 border-b" />
          <div className="relative">
            {timeSlots.map((slot, index) => (
              <div
                key={slot.id}
                className="absolute left-0 right-0 flex items-start justify-end pr-3 text-sm font-medium text-gray-500"
                style={{
                  top: `${(index * 80) + HEADER_HEIGHT}px`,
                  height: '80px'
                }}
              >
                {slot.time}
              </div>
            ))}
          </div>
        </div>
  
        {/* 메인 그리드 */}
        <div className="relative flex-1">
          {/* 컬럼 헤더 */}
          <div className="sticky top-0 z-10 flex border-b divide-x divide-gray-200 bg-gray-50/50 h-[72px]">
            {items.map(item => (
              <div key={item.id} className="flex-1 p-4 text-center">
                <div className="font-medium text-gray-900">{item.name}</div>
                <div className="mt-1 text-sm text-gray-500">
                  {type === 'tour' ? item.language : `${item.size}인실`}
                </div>
              </div>
            ))}
          </div>
  
          {/* 수평 시간 그리드 라인 */}
          <div className="absolute inset-0" style={{ height: `${(timeSlots.length * 80) + HEADER_HEIGHT}px` }}>
            {timeSlots.map((slot, index) => (
              <div
                key={slot.id}
                className={`absolute left-0 right-0 border-t ${
                  index === 0 ? 'border-transparent' : 'border-gray-100'
                }`}
                style={{ 
                  top: `${(index * 80) + HEADER_HEIGHT}px`
                }}
              />
            ))}
          </div>

          {/* 현재 시간 표시 라인 */}
          {currentTimePosition && (
            <div 
              className="absolute left-0 right-0 z-20 flex items-center"
              style={{ top: `${currentTimePosition+HEADER_HEIGHT}px` }}
            >
              <div className="w-4 h-4 -ml-2 rounded-full bg-sky-600" />
              <div className="flex-1 border-t-2 border-sky-600" />
            </div>
          )}
  
          {/* 예약 컬럼 */}
          <div
            className="relative flex divide-x divide-gray-200"
            style={{ height: `${(timeSlots.length * 80) + HEADER_HEIGHT}px` }}
          >
            {items.map(item => (
              <TimelineColumn
                key={item.id}
                type={type}
                timeSlots={timeSlots}
                reservations={reservations?.filter(res => {
                  if (!res) return false;
                  return type === 'tour'
                    ? res.available_tour?.docent?.id === item.id
                    : res.available_room?.room?.id === item.id;
                })}
                gridStartTime={startTime}
                onShowDetail={onShowDetail}
              />
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

// 메인 타임테이블 컴포넌트
export default function Timetable({
  viewMode,
  setViewMode,
  dates = [],
  tourTimeSlots = [],
  meetingTimeSlots = [],
  docents = [],
  rooms = [],
  approvedReservations = {}
}) {
  const [selectedDate, setSelectedDate] = useState(dates[0]?.date);
  const [selectedReservation, setSelectedReservation] = useState(null);

  if (!dates.length) {
    return (
      <Card className="p-8 text-center text-gray-500">
        조회 가능한 날짜가 없습니다.
      </Card>
    );
  }

  const dateReservations = approvedReservations[selectedDate] || { tours: [], meetings: [] };

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex gap-2 overflow-x-auto">
          {dates.map(date => (
            <button
              key={date.id}
              onClick={() => setSelectedDate(date.date)}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors whitespace-nowrap
                ${selectedDate === date.date
                  ? 'bg-sky-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              {format(new Date(date.date), 'M월 d일 (E)', { locale: ko })}
            </button>
          ))}
        </div>

        <Card className="p-1">
          <div className="flex divide-x divide-gray-200">
            <button
              onClick={() => setViewMode('all')}
              className={`px-4 py-1.5 text-sm font-medium transition-colors first:rounded-l-md last:rounded-r-md
                ${viewMode === 'all'
                  ? 'bg-sky-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              전체
            </button>
            <button
              onClick={() => setViewMode('tour')}
              className={`px-4 py-1.5 text-sm font-medium transition-colors first:rounded-l-md last:rounded-r-md
                ${viewMode === 'tour'
                  ? 'bg-sky-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              VIP 투어
            </button>
            <button
              onClick={() => setViewMode('meeting')}
              className={`px-4 py-1.5 text-sm font-medium transition-colors first:rounded-l-md last:rounded-r-md
                ${viewMode === 'meeting'
                  ? 'bg-sky-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'}`}
            >
              미팅룸
            </button>
          </div>
        </Card>
      </div>

      {(viewMode === 'all' || viewMode === 'tour') && (
        <div className="space-y-3">
          <h2 className="flex items-center gap-2 text-base font-medium text-gray-900">
            <Users className="w-5 h-5 text-sky-600" />
            VIP 투어
          </h2>
          <TimetableGrid
            type="tour"
            timeSlots={tourTimeSlots}
            items={docents}
            selectedDate={selectedDate}
            reservations={dateReservations.tours}
            onShowDetail={setSelectedReservation}
          />
        </div>
      )}

      {(viewMode === 'all' || viewMode === 'meeting') && (
        <div className="space-y-3">
          <h2 className="flex items-center gap-2 text-base font-medium text-gray-900">
            <Calendar className="w-5 h-5 text-emerald-600" />
            미팅룸
          </h2>
          <TimetableGrid
            type="meeting"
            timeSlots={meetingTimeSlots}
            items={rooms}
            selectedDate={selectedDate}
            reservations={dateReservations.meetings}
            onShowDetail={setSelectedReservation}
          />
        </div>
      )}

      <TimeTableDetailModal
        isOpen={!!selectedReservation}
        onClose={() => setSelectedReservation(null)}
        reservation={selectedReservation}
      />
    </div>
  );
}