import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, Info, X, MessageCircle, Users, Building2 } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import CancelModal from '../../components/user/CancelModal';
import api from '../../api/axios';
import { formatDateTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function MyReservationsPage() {
  const navigate = useNavigate();
  const [reservations, setReservations] = useState({ tours: [], meetings: [] });
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReservation, setCancelReservation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchReservations();
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await api.get('/api/user/my-reservations/');
      setReservations(response.data);
    } catch (error) {
      toast.error('예약 내역을 불러오는데 실패했습니다.');
    }
  };

  const handleCancelClick = (id, type) => {
    setCancelReservation({ id, type });
    setShowCancelModal(true);
  };

  const handleCancelConfirm = async () => {
    setIsSubmitting(true);
    try {
      await api.post(`/api/user/cancel-reservation/${cancelReservation.id}/`, {
        reservation_type: cancelReservation.type
      });
      toast.success('예약이 취소되었습니다.');
      setShowCancelModal(false);
      fetchReservations();
    } catch (error) {
      toast.error('예약 취소에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      if (!feedback.trim()) {
        toast.error('피드백 내용을 입력해주세요.');
        return;
      }
      
      await api.post(`/api/user/meeting-feedback/${selectedReservation.id}/`, {
        feedback
      });
      
      toast.success(
        selectedReservation.feedback 
          ? '피드백이 수정되었습니다.' 
          : '피드백이 제출되었습니다.'
      );
      
      setShowFeedbackDialog(false);
      setFeedback('');
      fetchReservations();
    } catch (error) {
      toast.error(
        selectedReservation.feedback 
          ? '피드백 수정에 실패했습니다.' 
          : '피드백 제출에 실패했습니다.'
      );
    }
  };

  const handleFeedbackDialogOpen = (meeting) => {
    setSelectedReservation(meeting);
    setFeedback(meeting.feedback || '');
    setShowFeedbackDialog(true);
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'APPROVED': return 'bg-green-500';
      case 'PENDING': return 'bg-yellow-500';
      case 'REJECTED': return 'bg-red-500';
      case 'CANCELLED': return 'bg-gray-500';
      default: return 'bg-gray-500';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'APPROVED': return '승인됨';
      case 'PENDING': return '승인대기';
      case 'REJECTED': return '거절됨';
      case 'CANCELLED': return '취소됨';
      default: return status;
    }
  };

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      {/* Header */}
      <header className="flex items-center mb-6">
        <button
          onClick={() => navigate(-1)}
          className="p-2 mr-2 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-bold">내 예약 내역</h1>
      </header>

      <Tabs defaultValue="tours" className="w-full">
        <TabsList className="grid w-full grid-cols-2 h-12 bg-gray-100/80 p-1.5 rounded-xl mb-6">
          <TabsTrigger 
            value="tours"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Users className="w-4 h-4" />
              <span className="font-medium">VIP 투어</span>
            </div>
          </TabsTrigger>
          <TabsTrigger 
            value="meetings"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Building2 className="w-4 h-4" />
              <span className="font-medium">미팅룸</span>
            </div>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tours">
          <div className="space-y-4">
            {reservations.tours.length > 0 ? (
              reservations.tours.map((tour) => (
                <Card key={tour.id}>
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>VIP 투어</span>
                      <span className={`px-2 py-1 text-sm text-white rounded-full ${getStatusBadgeColor(tour.status)}`}>
                        {getStatusText(tour.status)}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      <div className="text-lg">
                        {formatDateTime(
                          tour.available_tour.event_date.date,
                          tour.available_tour.tour_time.time
                        )}
                      </div>
                      <div className="text-sm text-gray-600">
                        도슨트: {tour.available_tour.docent.name} ({tour.available_tour.docent.language})
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <Dialog>
                          <DialogTrigger asChild>
                            <button
                              className="flex items-center px-3 py-2 text-sm border rounded-lg hover:bg-gray-50"
                              onClick={() => {
                                setSelectedReservation(tour);
                                setSelectedType('TOUR');
                              }}
                            >
                              <Info className="w-4 h-4 mr-2" />
                              상세 정보
                            </button>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>예약 상세 정보</DialogTitle>
                            </DialogHeader>
                            <div className="mt-4 space-y-4">
                              <div>
                                <div className="text-sm text-gray-600">방문자</div>
                                <div>{tour.visitor.name}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">회사명</div>
                                <div>{tour.visitor.company}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">연락처</div>
                                <div>{tour.visitor.phone}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">방문 인원</div>
                                <div>{tour.visitor.count}명</div>
                              </div>
                              {tour.note && (
                                <div>
                                  <div className="text-sm text-gray-600">요청사항</div>
                                  <div>{tour.note}</div>
                                </div>
                              )}
                            </div>
                          </DialogContent>
                        </Dialog>

                        {tour.status === 'PENDING' && (
                          <button
                            onClick={() => handleCancelClick(tour.id, 'VIP_TOUR')}
                            className="flex items-center px-3 py-2 text-sm text-red-600 border border-red-200 rounded-lg hover:bg-red-50"
                          >
                            <X className="w-4 h-4 mr-2" />
                            예약 취소
                          </button>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            ) : (
              <div className="py-8 text-center text-gray-500">
                예약된 투어가 없습니다
              </div>
            )}
          </div>
        </TabsContent>

        <TabsContent value="meetings">
          <div className="space-y-4">
            {reservations.meetings.length > 0 ? (
              reservations.meetings.map((meeting) => (
                <Card key={meeting.id}>
                  <CardHeader>
                    <CardTitle className="flex items-center justify-between">
                      <span>{meeting.available_room.room.name}</span>
                      <span className={`px-2 py-1 text-sm text-white rounded-full ${getStatusBadgeColor(meeting.status)}`}>
                        {getStatusText(meeting.status)}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      <div className="text-lg">
                        {formatDateTime(
                          meeting.available_room.event_date.date,
                          meeting.available_room.room_time.time
                        )}
                      </div>
                      <div className="text-sm text-gray-600">
                        최대 {meeting.available_room.room.size}인 수용
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <Dialog>
                          <DialogTrigger asChild>
                            <button
                              className="flex items-center px-3 py-2 text-sm border rounded-lg hover:bg-gray-50"
                              onClick={() => {
                                setSelectedReservation(meeting);
                                setSelectedType('MEETING');
                              }}
                            >
                              <Info className="w-4 h-4 mr-2" />
                              상세 정보
                            </button>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>예약 상세 정보</DialogTitle>
                            </DialogHeader>
                            <div className="mt-4 space-y-4">
                              <div>
                                <div className="text-sm text-gray-600">미팅 목적</div>
                                <div>{meeting.visitor.title}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">방문자</div>
                                <div>{meeting.visitor.name}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">연락처</div>
                                <div>{meeting.visitor.phone}</div>
                              </div>
                              <div>
                                <div className="text-sm text-gray-600">방문 인원</div>
                                <div>{meeting.visitor.count}명</div>
                              </div>
                              {meeting.note && (
                                <div>
                                  <div className="text-sm text-gray-600">요청사항</div>
                                  <div>{meeting.note}</div>
                                </div>
                              )}
                            </div>
                          </DialogContent>
                        </Dialog>

                        <div className="flex gap-2">
                          {meeting.status === 'PENDING' && (
                            <button
                              onClick={() => handleCancelClick(meeting.id, 'MEETING_ROOM')}
                              className="flex items-center px-3 py-2 text-sm text-red-600 border border-red-200 rounded-lg hover:bg-red-50"
                            >
                              <X className="w-4 h-4 mr-2" />
                              예약 취소
                            </button>
                          )}
                          {meeting.status === 'APPROVED' && (
                            <button
                              onClick={() => {
                                setSelectedReservation(meeting);
                                setFeedback(meeting.feedback || ''); // 기존 피드백이 있으면 설정
                                setShowFeedbackDialog(true);
                              }}
                              className="flex items-center px-3 py-2 text-sm border rounded-lg text-sky-600 border-sky-200 hover:bg-sky-50"
                            >
                              <MessageCircle className="w-4 h-4 mr-2" />
                              {meeting.feedback ? '피드백 수정' : '피드백 작성'}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))
            ) : (
              <div className="py-8 text-center text-gray-500">
                예약된 미팅이 없습니다
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>

      {/* Feedback Dialog */}
      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {selectedReservation?.feedback ? '미팅 피드백 수정' : '미팅 피드백 작성'}
            </DialogTitle>
          </DialogHeader>
          <div className="mt-4 space-y-4">
            {selectedReservation?.feedback && (
              <div className="p-3 text-sm rounded-lg bg-gray-50">
                <div className="font-medium text-gray-600">기존 피드백</div>
                <div className="mt-1">{selectedReservation.feedback}</div>
              </div>
            )}
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder={
                selectedReservation?.feedback
                  ? '수정할 피드백을 작성해주세요.'
                  : '피드백을 작성해주세요.'
              }
              className="w-full h-32 p-3 border rounded-lg resize-none focus:ring-2 focus:ring-sky-500"
            />
            <div className="flex justify-end gap-2">
              <DialogClose asChild>
                <button className="px-4 py-2 text-sm border rounded-lg hover:bg-gray-50">
                  취소
                </button>
              </DialogClose>
              <button
                onClick={handleFeedbackSubmit}
                className="px-4 py-2 text-sm text-white rounded-lg bg-sky-600 hover:bg-sky-700"
              >
                {selectedReservation?.feedback ? '수정하기' : '제출하기'}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Cancel Modal 추가 */}
      <CancelModal 
        open={showCancelModal}
        onOpenChange={setShowCancelModal}
        onConfirm={handleCancelConfirm}
        reservationType={cancelReservation?.type}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}