import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Card } from "../../components/ui/card";
import AdminHeader from '../../components/admin/layout/AdminHeader';
import DashboardStats from '../../components/admin/layout/DashboardStats';
import Timetable from '../../components/admin/reservation/Timetable';
import ReservationList from '../../components/admin/reservation/ReservationList';
import { useAdminDashboard } from '../../hooks/useAdminDashboard';
import api from '../../api/axios';

export default function AdminDashboardPage() {
  const [activeTab, setActiveTab] = useState('list');
  const [viewMode, setViewMode] = useState('all');
  
  const { 
    dates, 
    tourTimeSlots,
    meetingTimeSlots,
    docents,
    rooms,
    approvedReservations,
    pendingReservations,
    stats,
    loading,
    error,
    refreshData
  } = useAdminDashboard();

  const handleApprove = async (reservation) => {
    try {
      await api.post(`/api/admin/reservation/approve/${reservation.id}/`, {
        reservation_type: reservation.type === 'tour' ? 'VIP_TOUR' : 'MEETING_ROOM'
      });
      toast.success('예약이 승인되었습니다.');
      refreshData();
    } catch (error) {
      console.error('Error approving reservation:', error);
      toast.error('예약 승인 중 오류가 발생했습니다.');
    }
  };

  const handleReject = async (reservation) => {
    const reason = window.prompt('거절 사유를 입력해주세요:');
    if (reason === null) return;

    try {
      await api.post(`/api/admin/reservation/reject/${reservation.id}/`, {
        reservation_type: reservation.type === 'tour' ? 'VIP_TOUR' : 'MEETING_ROOM',
        reason
      });
      toast.success('예약이 거절되었습니다.');
      refreshData();
    } catch (error) {
      console.error('Error rejecting reservation:', error);
      toast.error('예약 거절 중 오류가 발생했습니다.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center h-64">
            <div className="w-8 h-8 border-b-2 rounded-full border-sky-600 animate-spin"></div>
          </div>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <AdminHeader />
        <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <Card className="p-4 text-center text-red-600 border-red-200 bg-red-50">
            {error}
          </Card>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminHeader />
      <main className="max-w-[98%] mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <DashboardStats stats={stats} />
        
        <Card className="mb-6">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab('list')}
                className={`relative px-6 py-4 text-sm font-medium transition-colors
                  ${activeTab === 'list'
                    ? 'text-sky-600'
                    : 'text-gray-500 hover:text-gray-700'}`}
              >
                예약 신청 목록
                {activeTab === 'list' && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-sky-600" />
                )}
              </button>
              <button
                onClick={() => setActiveTab('timetable')}
                className={`relative px-6 py-4 text-sm font-medium transition-colors
                  ${activeTab === 'timetable'
                    ? 'text-sky-600'
                    : 'text-gray-500 hover:text-gray-700'}`}
              >
                스케줄
                {activeTab === 'timetable' && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-sky-600" />
                )}
              </button>
            </nav>
          </div>

          <div className="p-4">
            {activeTab === 'list' && (
              <ReservationList
                dates={dates}
                pendingReservations={pendingReservations}
                onApprove={handleApprove}
                onReject={handleReject}
              />
            )}

            {activeTab === 'timetable' && (
              <Timetable 
                viewMode={viewMode}
                setViewMode={setViewMode}
                dates={dates}
                tourTimeSlots={tourTimeSlots}
                meetingTimeSlots={meetingTimeSlots}
                docents={docents}
                rooms={rooms}
                approvedReservations={approvedReservations}
              />
            )}
          </div>
        </Card>
      </main>
    </div>
  );
}