import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarDays, Clock, Users } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import PageHeader from '../../components/user/PageHeader';
import StepProgress from '../../components/user/StepProgress';
import NavigationButtons from '../../components/user/NavigationButtons';
import VisitorForm from '../../components/user/VisitorForm';
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function TourReservationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.state || {};

  const [step, setStep] = useState(() => {
    if (initialState.date) return 2;
    return 1;
  });

  const [dates, setDates] = useState([]);
  const [docents, setDocents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialState.date || '');
  const [selectedDocent, setSelectedDocent] = useState(null);
  const [availableTours, setAvailableTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [visitorInfo, setVisitorInfo] = useState({
    company: '',
    name: '',
    phone: '',
    count: 1,
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const [datesResponse, docentsResponse] = await Promise.all([
          api.get('/api/user/event-dates/'),
          api.get('/api/user/docents/')
        ]);

        setDates(datesResponse.data);
        setDocents(docentsResponse.data);

        if (initialState.docentId) {
          const docent = docentsResponse.data.find(
            d => d.id === parseInt(initialState.docentId)
          );
          if (docent) {
            setSelectedDocent(docent);
            setStep(3);
          }
        }
      } catch (error) {
        toast.error('데이터를 불러오는데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [initialState.docentId]);

  const fetchAvailableTours = async (date, docent) => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/user/available-tours/', {
        params: {
          date: date,
          docent_id: docent.id
        }
      });

      const sortedTours = response.data.sort((a, b) => {
        return a.tour_time.time.localeCompare(b.tour_time.time);
      });

      setAvailableTours(sortedTours);

      if (initialState.timeId) {
        const tour = sortedTours.find(t => t.tour_time.id === initialState.timeId);
        if (tour) {
          setSelectedTour(tour);
        }
      }
    } catch (error) {
      toast.error('가용 시간을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate && selectedDocent) {
      fetchAvailableTours(selectedDate, selectedDocent);
    }
  }, [selectedDate, selectedDocent?.id]);

  const canGoNext = () => {
    switch (step) {
      case 1:
        return !!selectedDate;
      case 2:
        return !!selectedDocent;
      case 3:
        return !!selectedTour;
      case 4:
        return true;
      default:
        return false;
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      if (step === 4) {
        setSelectedTour(null);
      } else if (step === 3) {
        setSelectedDocent(null);
      } else if (step === 2) {
        setSelectedDate('');
      }
      setStep(prev => prev - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (canGoNext() && step < 4) {
      setStep(prev => prev + 1);
    }
  };

  const validateVisitorInfo = () => {
    if (!visitorInfo.company.trim()) {
      toast.error('회사명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.name.trim()) {
      toast.error('방문자명을 입력해주세요.');
      return false;
    }
    if (!visitorInfo.phone.trim() || visitorInfo.phone.replace(/-/g, '').length !== 11) {
      toast.error('올바른 연락처를 입력해주세요.');
      return false;
    }
    if (visitorInfo.count < 1) {
      toast.error('방문 인원을 입력해주세요.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateVisitorInfo()) return;

    try {
      await api.post('/api/user/reserve-tour/', {
        available_tour_id: selectedTour.id,
        visitor: visitorInfo
      });
      
      toast.success('투어 예약이 신청되었습니다.');
      navigate('/dashboard', { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.error || '예약 신청에 실패했습니다.');
    }
  };

  if (isLoading) {
    return (
      <div className="container max-w-md min-h-screen p-4 mx-auto">
        <div className="flex items-center justify-center h-screen">
          <div className="w-6 h-6 border-2 rounded-full border-t-transparent border-sky-600 animate-spin" />
        </div>
      </div>
    );
  }

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <CalendarDays className="w-5 h-5" />
                날짜 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-3">
                {dates.map((date) => (
                  <button
                    key={date.id}
                    onClick={() => setSelectedDate(date.date)}
                    className={`flex items-center justify-between w-full p-4 text-left border rounded-lg hover:bg-gray-50 ${
                      selectedDate === date.date ? 'border-sky-500 bg-sky-50' : ''
                    }`}
                  >
                    <span className="font-medium">
                      {new Date(date.date).toLocaleDateString()}
                    </span>
                  </button>
                ))}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Users className="w-5 h-5" />
                도슨트 선택
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedDocent ? selectedDocent.id : ''}
                onChange={(e) => {
                  const docent = docents.find(d => d.id === parseInt(e.target.value));
                  setSelectedDocent(docent);
                }}
                className="w-full px-4 py-3 text-lg border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">도슨트를 선택하세요</option>
                {docents.map((docent) => (
                  <option key={docent.id} value={docent.id}>
                    {docent.name} ({docent.language})
                  </option>
                ))}
              </select>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

      case 3:
        return (
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Clock className="w-5 h-5" />
                시간 선택
              </CardTitle>
              <CardDescription>
                {selectedDocent?.name} ({selectedDocent?.language})
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-3">
                {availableTours.length > 0 ? (
                  availableTours.map((tour) => (
                    <button
                      key={tour.id}
                      onClick={() => setSelectedTour(tour)}
                      className={`flex items-center justify-center w-full p-4 text-center border rounded-lg hover:bg-gray-50 ${
                        selectedTour?.id === tour.id ? 'border-sky-500 bg-sky-50' : ''
                      }`}
                    >
                      <span className="font-medium">
                        {formatTime(tour.tour_time.time)}
                      </span>
                    </button>
                  ))
                ) : (
                  <div className="col-span-2 py-8 text-center text-gray-500">
                    예약 가능한 시간이 없습니다
                  </div>
                )}
              </div>
              <div className="mt-6">
                <NavigationButtons
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  currentStep={step}
                  canGoNext={canGoNext()}
                />
              </div>
            </CardContent>
          </Card>
        );

        case 4:
          return (
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Users className="w-5 h-5" />
                  방문자 정보
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="p-5 mb-6 border rounded-lg bg-white/50 backdrop-blur-sm">
                  <h3 className="mb-3 text-sm font-medium text-gray-800">예약 정보</h3>
                  <dl className="grid gap-3">
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <CalendarDays className="w-4 h-4" />
                          <span>날짜</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        {new Date(selectedDate).toLocaleDateString()}
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Users className="w-4 h-4" />
                          <span>도슨트</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        <div className="text-right">
                          <div>{selectedDocent.name}</div>
                          <div className="text-xs text-gray-500">{selectedDocent.language}</div>
                        </div>
                      </dd>
                    </div>
                    <div className="grid grid-cols-[1fr,2fr] items-center p-2 rounded bg-gray-50">
                      <dt className="text-sm text-gray-600">
                        <div className="flex items-center gap-1.5">
                          <Clock className="w-4 h-4" />
                          <span>시간</span>
                        </div>
                      </dt>
                      <dd className="text-sm font-medium justify-self-end">
                        {formatTime(selectedTour?.tour_time.time)}
                      </dd>
                    </div>
                  </dl>
                </div>
        
                <VisitorForm 
                  visitorInfo={visitorInfo}
                  onChange={setVisitorInfo}
                  type="tour"
                />
                <div className="mt-6">
                  <NavigationButtons
                    onPrevious={handlePrevious}
                    onNext={handleSubmit}
                    currentStep={step}
                    isLastStep={true}
                    canGoNext={true}
                  />
                </div>
              </CardContent>
            </Card>
          );

      default:
        return null;
    }
  };

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      <PageHeader 
        title="VIP 투어 예약" 
        onBack={handlePrevious}
      />
      <StepProgress currentStep={step} />
      {renderStepContent()}
    </div>
  );
}