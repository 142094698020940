import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { Trash2 } from 'lucide-react';

export default function DocentsManagement() {
  const [docents, setDocents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDocents, setSelectedDocents] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    language: '',
    start_point: ''
  });

  useEffect(() => {
    fetchDocents();
  }, []);

  const fetchDocents = async () => {
    try {
      const response = await api.get('/api/admin/docents/');
      setDocents(response.data);
      setSelectedDocents([]); // 데이터를 새로 불러올 때 선택 초기화
    } catch (error) {
      toast.error('도슨트 목록을 불러오는데 실패했습니다.');
      console.error('Error fetching docents:', error);
    }
  };

  const validateForm = () => {
    if (formData.name.trim().length < 2) {
      toast.error('이름은 2자 이상이어야 합니다.');
      return false;
    }
    if (formData.language.trim().length === 0) {
      toast.error('언어를 입력해주세요.');
      return false;
    }
    if (formData.start_point.trim().length === 0) {
      toast.error('시작 위치를 입력해주세요.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await api.post('/api/admin/docents/', {
        name: formData.name.trim(),
        language: formData.language.trim(),
        start_point: formData.start_point.trim()
      });
      await fetchDocents();
      setFormData({ name: '', language: '', start_point: '' });
      toast.success('도슨트가 성공적으로 추가되었습니다.');
    } catch (error) {
      let errorMessage = '도슨트 추가에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      console.error('Error adding docent:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id, name) => {
    if (!window.confirm(`정말로 "${name}" 도슨트를 삭제하시겠습니까?`)) {
      return;
    }

    try {
      await api.delete(`/api/admin/docents/${id}/`);
      await fetchDocents();
      toast.success(`"${name}" 도슨트가 삭제되었습니다.`);
    } catch (error) {
      let errorMessage = '도슨트 삭제에 실패했습니다.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
      console.error('Error deleting docent:', error);
    }
  };

  // 전체 선택 처리
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDocents(docents.map(docent => docent.id));
    } else {
      setSelectedDocents([]);
    }
  };

  // 개별 선택 처리
  const handleSelectDocent = (docentId) => {
    setSelectedDocents(prev => {
      if (prev.includes(docentId)) {
        return prev.filter(id => id !== docentId);
      } else {
        return [...prev, docentId];
      }
    });
  };

  // 선택된 도슨트 일괄 삭제
  const handleBulkDelete = async () => {
    if (selectedDocents.length === 0) {
      toast.error('삭제할 도슨트를 선택해주세요.');
      return;
    }

    const confirmMessage = selectedDocents.length === docents.length
      ? '모든 도슨트를 삭제하시겠습니까?'
      : `선택한 ${selectedDocents.length}명의 도슨트를 삭제하시겠습니까?`;
    
    if (!window.confirm(confirmMessage)) return;

    try {
      await api.delete('/api/admin/docents/', {
        data: { docent_ids: selectedDocents }
      });
      await fetchDocents();
      toast.success(`선택한 ${selectedDocents.length}명의 도슨트가 삭제되었습니다.`);
    } catch (error) {
      toast.error('도슨트 일괄 삭제에 실패했습니다.');
      console.error('Error bulk deleting docents:', error);
    }
  };

  // 전체 도슨트 삭제
  const handleDeleteAll = async () => {
    if (docents.length === 0) {
      toast.error('삭제할 도슨트가 없습니다.');
      return;
    }

    if (!window.confirm('정말로 모든 도슨트를 삭제하시겠습니까?')) return;

    try {
      await api.delete('/api/admin/docents/', {
        data: { delete_all: true }
      });
      await fetchDocents();
      toast.success('모든 도슨트가 삭제되었습니다.');
    } catch (error) {
      toast.error('전체 도슨트 삭제에 실패했습니다.');
      console.error('Error deleting all docents:', error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium">도슨트 관리</h2>
        <div className="flex gap-2">
          {selectedDocents.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              선택 삭제 ({selectedDocents.length})
            </button>
          )}
          <button
            onClick={handleDeleteAll}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            전체 삭제
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4 mb-6">
        <input
          type="text"
          placeholder="이름"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          maxLength={50}
        />
        <input
          type="text"
          placeholder="언어 (예: 국문, 영문)"
          value={formData.language}
          onChange={(e) => setFormData({ ...formData, language: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          maxLength={20}
        />
        <input
          type="text"
          placeholder="시작 위치"
          value={formData.start_point}
          onChange={(e) => setFormData({ ...formData, start_point: e.target.value })}
          className="px-3 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          required
          maxLength={100}
        />
        <button
          type="submit"
          disabled={isLoading}
          className={`px-4 py-2 text-white rounded flex items-center justify-center
            ${isLoading 
              ? 'bg-blue-400 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700'
            }`}
        >
          {isLoading ? '추가 중...' : '추가'}
        </button>
      </form>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left">
                <input
                  type="checkbox"
                  checked={selectedDocents.length === docents.length && docents.length > 0}
                  onChange={handleSelectAll}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                이름
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                언어
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                시작 위치
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase">
                작업
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {docents.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                  등록된 도슨트가 없습니다.
                </td>
              </tr>
            ) : (
              docents.map((docent) => (
                <tr key={docent.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedDocents.includes(docent.id)}
                      onChange={() => handleSelectDocent(docent.id)}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{docent.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{docent.language}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{docent.start_point}</td>
                  <td className="px-6 py-4 text-right whitespace-nowrap">
                    <button
                      onClick={() => handleDelete(docent.id, docent.name)}
                      className="p-1 text-red-600 rounded hover:text-red-800 hover:bg-red-50"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}