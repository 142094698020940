import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';

export default function TimeManagement({ type }) {
  const [times, setTimes] = useState([]);
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [interval, setInterval] = useState(type === 'tour' ? 10 : 30);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchTimes();
  }, [type]);

  const fetchTimes = async () => {
    try {
      const endpoint = type === 'tour' ? '/api/admin/tour-times/' : '/api/admin/room-times/';
      const response = await api.get(endpoint);
      setTimes(response.data);
    } catch (error) {
      toast.error(
        `${type === 'tour' ? '투어' : '미팅룸'} 시간 목록을 불러오는데 실패했습니다.`
      );
      console.error('Error fetching times:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // 시간 유효성 검사
    if (startTime >= endTime) {
      toast.error('시작 시간은 종료 시간보다 빨라야 합니다.');
      setIsLoading(false);
      return;
    }

    // 간격 유효성 검사
    if (interval <= 0) {
      toast.error('시간 간격은 0보다 커야 합니다.');
      setIsLoading(false);
      return;
    }

    try {
      const endpoint = type === 'tour' ? '/api/admin/tour-times/' : '/api/admin/room-times/';
      const response = await api.post(endpoint, {
        start_time: startTime,
        end_time: endTime,
        interval_minutes: parseInt(interval)
      });
      
      if (response.data.time_slots) {
        setTimes(response.data.time_slots);
        toast.success(
          `${type === 'tour' ? '투어' : '미팅룸'} 시간이 성공적으로 생성되었습니다.`
        );
      }
    } catch (error) {
      let errorMessage = `${type === 'tour' ? '투어' : '미팅룸'} 시간 설정에 실패했습니다.`;
      
      // 서버에서 상세 에러 메시지가 있는 경우
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      
      toast.error(errorMessage);
      console.error('Error setting times:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <h2 className="mb-4 text-lg font-medium">
        {type === 'tour' ? '투어 시간 관리' : '미팅룸 시간 관리'}
      </h2>

      <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4 mb-6">
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            시작 시간
          </label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            마지막 시간
          </label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            시간 간격 (분)
          </label>
          <input
            type="number"
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            min="1"
            required
          />
        </div>
        <div className="flex items-end">
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full px-4 py-2 text-white rounded 
              ${isLoading 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
              }`}
          >
            {isLoading ? '생성 중...' : '시간대 생성'}
          </button>
        </div>
      </form>

      <div className="grid grid-cols-4 gap-4">
        {times.map((time, index) => (
          <div
            key={time.id || index}
            className="px-4 py-2 text-center bg-gray-100 rounded"
          >
            {time.time}
          </div>
        ))}
      </div>
    </div>
  );
}