import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Users, Building2 } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import ReservationModal from "../../components/user/ReservationModal";
import api from '../../api/axios';
import { formatTime } from '../../lib/utils';
import { toast } from 'react-hot-toast';

export default function DashboardPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDocent, setSelectedDocent] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [scheduleData, setScheduleData] = useState({
    tour_schedule: [],
    room_schedule: []
  });
  const [tourTimeSlots, setTourTimeSlots] = useState([]);
  const [roomTimeSlots, setRoomTimeSlots] = useState([]);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [reservations, setReservations] = useState({ tours: [], meetings: [] });

  useEffect(() => {
    const fetchEventDates = async () => {
      try {
        const response = await api.get('/api/user/event-dates/');
        setDates(response.data);
        if (response.data.length > 0) {
          setSelectedDate(response.data[0].date);
        }
      } catch (error) {
        toast.error('행사 일정을 불러오는데 실패했습니다.');
      }
    };
    
    fetchEventDates();
  }, []);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await api.get('/api/user/my-reservations/');
        
        const approvedTours = response.data.tours.filter(tour => tour.status === 'APPROVED');
        const approvedMeetings = response.data.meetings.filter(meeting => meeting.status === 'APPROVED');
        
        setReservations({
          tours: approvedTours,
          meetings: approvedMeetings
        });

        if (location.state?.fromLogin && (approvedTours.length > 0 || approvedMeetings.length > 0)) {
          setShowReservationModal(true);
        }
      } catch (error) {
        toast.error('예약 내역을 불러오는데 실패했습니다.');
      }
    };

    fetchReservations();
  }, [location.state]);

  useEffect(() => {
    if (!selectedDate) return;
  
    const fetchSchedule = async () => {
      try {
        const response = await api.get(`/api/user/schedule/?date=${selectedDate}`);
        setScheduleData(response.data);
      } catch (error) {
        if (error.response?.status === 404) {
          setScheduleData({ tour_schedule: [], room_schedule: [] });
          setTourTimeSlots([]);
          setRoomTimeSlots([]);
        } else {
          toast.error('예약 현황을 불러오는데 실패했습니다.');
        }
      }
    };
  
    fetchSchedule();
  }, [selectedDate]);

  useEffect(() => {
    if (!selectedDocent) {
      setTourTimeSlots([]);
      return;
    }
    
    if (scheduleData.tour_schedule) {
      const selectedSchedule = scheduleData.tour_schedule.find(
        schedule => schedule.docent.id === parseInt(selectedDocent)
      );
      setTourTimeSlots(selectedSchedule?.time_slots || []);
    }
  }, [selectedDocent, scheduleData]);

  useEffect(() => {
    if (!selectedRoom) {
      setRoomTimeSlots([]);
      return;
    }
  
    if (scheduleData.room_schedule) {
      const selectedSchedule = scheduleData.room_schedule.find(
        schedule => schedule.room.id === parseInt(selectedRoom)
      );
      setRoomTimeSlots(selectedSchedule?.time_slots || []);
    }
  }, [selectedRoom, scheduleData]);

  const getStatusStyle = (slot) => {
    if (slot.reservation) {
      return 'bg-sky-50 border-sky-200';
    }
    if (!slot.is_available) {
      return 'bg-gray-100 text-gray-500';
    }
    return 'hover:bg-gray-50 cursor-pointer';
  };

  const handleViewAllReservations = () => {
    setShowReservationModal(false);
    navigate('/reservations');
  };

  const handleTourReservation = () => {
    if (selectedDocent && selectedDate) {
      const selectedSchedule = scheduleData.tour_schedule.find(
        schedule => schedule.docent.id === parseInt(selectedDocent)
      );
      navigate('/reserve-tour', {
        state: {
          date: selectedDate,
          docentId: selectedDocent,
          docentName: selectedSchedule?.docent.name,
          docentLanguage: selectedSchedule?.docent.language,
        }
      });
    } else {
      navigate('/reserve-tour');
    }
  };

  const handleRoomReservation = () => {
    if (selectedRoom && selectedDate) {
      const selectedSchedule = scheduleData.room_schedule.find(
        schedule => schedule.room.id === parseInt(selectedRoom)
      );
      navigate('/reserve-room', {
        state: {
          date: selectedDate,
          roomId: selectedRoom,
          roomName: selectedSchedule?.room.name,
          roomSize: selectedSchedule?.room.size,
        }
      });
    } else {
      navigate('/reserve-room');
    }
  };

  return (
    <div className="container max-w-md min-h-screen p-4 pb-20 mx-auto">
      <header className="mb-6">
        <h1 className="text-2xl font-bold text-sky-700">CES 2025 예약</h1>
        <p className="font-bold text-gray-600">실시간 예약 현황</p>
      </header>

      <Card className="mb-6">
        <CardContent className="pt-6">
          <select
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value);
              setSelectedDocent("");
              setSelectedRoom("");
              setTourTimeSlots([]);
              setRoomTimeSlots([]);
            }}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-sky-500"
          >
            {dates.map((date) => (
              <option key={date.id} value={date.date}>
                {new Date(date.date).toLocaleDateString()}
              </option>
            ))}
          </select>
        </CardContent>
      </Card>

      <Tabs defaultValue="tours" className="w-full" onValueChange={() => {
        setSelectedDocent("");
        setSelectedRoom("");
        setTourTimeSlots([]);
        setRoomTimeSlots([]);
      }}>
        <TabsList className="grid w-full grid-cols-2 h-12 bg-gray-100/80 p-1.5 rounded-xl">
          <TabsTrigger 
            value="tours"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Users className="w-4 h-4" />
              <span className="font-medium">VIP 투어</span>
            </div>
          </TabsTrigger>
          <TabsTrigger 
            value="rooms"
            className="rounded-lg data-[state=active]:bg-white data-[state=active]:text-sky-700 data-[state=active]:shadow-sm"
          >
            <div className="flex items-center justify-center gap-2">
              <Building2 className="w-4 h-4" />
              <span className="font-medium">미팅룸</span>
            </div>
          </TabsTrigger>
        </TabsList>

        <TabsContent value="tours">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Users className="w-5 h-5" />
                VIP 투어 예약 현황
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedDocent}
                onChange={(e) => setSelectedDocent(e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">도슨트를 선택하세요</option>
                {scheduleData.tour_schedule?.map((schedule) => (
                  <option key={schedule.docent.id} value={schedule.docent.id}>
                    {schedule.docent.name} ({schedule.docent.language})
                  </option>
                ))}
              </select>

              {tourTimeSlots.length > 0 ? (
                <div className="grid grid-cols-4 gap-1">
                  {tourTimeSlots.map((slot) => (
                    <div
                      key={`${selectedDocent}-${slot.time.id}`}
                      className={`p-2 border rounded-lg ${getStatusStyle(slot)}`}
                      onClick={() => {
                        if (slot.is_available && !slot.reservation) {
                          const selectedSchedule = scheduleData.tour_schedule.find(
                            schedule => schedule.docent.id === parseInt(selectedDocent)
                          );
                          navigate('/reserve-tour', {
                            state: {
                              date: selectedDate,
                              docentId: selectedDocent,
                              timeId: slot.time.id,
                              docentName: selectedSchedule?.docent.name,
                              docentLanguage: selectedSchedule?.docent.language,
                              time: slot.time.time
                            }
                          });
                        }
                      }}
                    >
                      <div className="text-center">
                        <div className="text-xs font-medium">
                          {formatTime(slot.time.time)}
                        </div>
                        {slot.reservation ? (
                          <>
                            <div className="text-[10px] text-gray-600 truncate">
                              {slot.reservation.company}
                            </div>
                            <div className="text-xs text-gray-500 truncate">
                              {slot.reservation.name}
                            </div>
                          </>
                        ) : slot.is_available ? (
                          <div className="text-xs truncate text-sky-600">
                            가능
                          </div>
                        ) : (
                          <div className="text-xs text-gray-500 truncate">
                            불가
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="py-8 text-center text-gray-500">
                  도슨트를 선택하면 <br></br>예약 현황이 표시됩니다.
                </div>
              )}

              <button
                onClick={handleTourReservation}
                className="w-full py-3 mt-6 mb-4 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
              >
                투어 예약하기
              </button>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="rooms">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Building2 className="w-5 h-5" />
                미팅룸 예약 현황
              </CardTitle>
            </CardHeader>
            <CardContent>
              <select
                value={selectedRoom}
                onChange={(e) => setSelectedRoom(e.target.value)}
                className="w-full px-4 py-2 mb-4 border rounded-lg focus:ring-2 focus:ring-sky-500"
              >
                <option value="">미팅룸을 선택하세요</option>
                {scheduleData.room_schedule?.map((schedule) => (
                  <option key={schedule.room.id} value={schedule.room.id}>
                    {schedule.room.name} (최대 {schedule.room.size}인)
                  </option>
                ))}
              </select>

              {roomTimeSlots.length > 0 ? (
                <div className="grid grid-cols-4 gap-1">
                  {roomTimeSlots.map((slot) => (
                    <div
                      key={`${selectedRoom}-${slot.time.id}`}
                      className={`p-2 border rounded-lg ${getStatusStyle(slot)}`}
                      onClick={() => {
                        if (slot.is_available && !slot.reservation) {
                          const selectedSchedule = scheduleData.room_schedule.find(
                            schedule => schedule.room.id === parseInt(selectedRoom)
                          );
                          navigate('/reserve-room', {
                            state: {
                              date: selectedDate,
                              roomId: selectedRoom,
                              timeId: slot.time.id,
                              roomName: selectedSchedule?.room.name,
                              roomSize: selectedSchedule?.room.size,
                              time: slot.time.time
                            }
                          });
                        }
                      }}
                    >
                      <div className="text-center">
                        <div className="text-sm font-medium">
                          {formatTime(slot.time.time)}
                        </div>
                        {slot.reservation ? (
                          <>
                            <div className="text-[10px] text-gray-600 truncate">
                              {slot.reservation.company}
                            </div>
                            <div className="text-xs text-gray-500 truncate">
                              {slot.reservation.name}
                            </div>
                          </>
                        ) : slot.is_available ? (
                          <div className="text-xs truncate text-sky-600">
                            가능
                          </div>
                        ) : (
                          <div className="text-xs text-gray-500 truncate">
                            불가
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="py-8 text-center text-gray-500">
                  미팅룸을 선택하면 <br></br>예약 현황이 표시됩니다.
                </div>
              )}

              <button
                onClick={handleRoomReservation}
                className="w-full py-3 mt-6 mb-4 text-white transition-colors rounded-lg bg-sky-700 hover:bg-sky-800"
              >
                미팅룸 예약하기
              </button>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <ReservationModal 
        open={showReservationModal}
        onOpenChange={setShowReservationModal}
        reservations={reservations}
        onViewAll={handleViewAllReservations}
      />
    </div>
  );
}