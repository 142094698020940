import { useState } from 'react';
import AdminHeader from '../../components/admin/layout/AdminHeader';
import DateManagement from '../../components/admin/settings/DateManagement';
import TimeManagement from '../../components/admin/settings/TimeManagement';
import DocentsManagement from '../../components/admin/settings/DocentsManagement';
import RoomsManagement from '../../components/admin/settings/RoomsManagement';
import AvailabilityManagement from '../../components/admin/settings/AvailabilityManagement';

export default function AdminSettingsPage() {
 const [activeTab, setActiveTab] = useState('dates');

 return (
   <div className="min-h-screen bg-gray-100">
     <AdminHeader />
     <main className="max-w-[98%] mx-auto py-6 sm:px-6 lg:px-8">
       <div className="mb-4">
         <div className="border-b border-gray-200">
           <nav className="flex -mb-px">
             <button
               onClick={() => setActiveTab('dates')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'dates'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               날짜 관리
             </button>
             <button
               onClick={() => setActiveTab('tour-times')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'tour-times'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               투어 시간 관리
             </button>
             <button
               onClick={() => setActiveTab('meeting-times')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'meeting-times'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               미팅룸 시간 관리
             </button>
             <button
               onClick={() => setActiveTab('docents')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'docents'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               도슨트 관리
             </button>
             <button
               onClick={() => setActiveTab('rooms')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'rooms'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               미팅룸 관리
             </button>
             <button
               onClick={() => setActiveTab('tour-availability')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'tour-availability'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               도슨트 가용시간
             </button>
             <button
               onClick={() => setActiveTab('room-availability')}
               className={`w-1/7 py-4 px-2 text-center border-b-2 font-medium text-sm
                 ${activeTab === 'room-availability'
                   ? 'border-blue-500 text-blue-600'
                   : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                 }`}
             >
               미팅룸 가용시간
             </button>
           </nav>
         </div>
       </div>

       {activeTab === 'dates' && <DateManagement />}
       {activeTab === 'tour-times' && <TimeManagement type="tour" />}
       {activeTab === 'meeting-times' && <TimeManagement type="meeting" />}
       {activeTab === 'docents' && <DocentsManagement />}
       {activeTab === 'rooms' && <RoomsManagement />}
       {activeTab === 'tour-availability' && <AvailabilityManagement type="tour" />}
       {activeTab === 'room-availability' && <AvailabilityManagement type="room" />}
     </main>
   </div>
 );
}