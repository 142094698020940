import { useState, useEffect } from 'react';
import api from '../../../api/axios';
import { toast } from 'react-hot-toast';

export default function AvailabilityManagement({ type }) {
 const [resources, setResources] = useState([]); // 도슨트 또는 미팅룸 목록
 const [dates, setDates] = useState([]); // 날짜 목록 
 const [times, setTimes] = useState([]); // 시간 목록
 const [selectedResource, setSelectedResource] = useState('');
 const [selectedDate, setSelectedDate] = useState('');
 const [selectedTimes, setSelectedTimes] = useState([]);
 const [availableSlots, setAvailableSlots] = useState([]);
 const [searchResource, setSearchResource] = useState('');
 const [searchDate, setSearchDate] = useState('');
 const [allAvailableSlots, setAllAvailableSlots] = useState([]);
 const [groupedByDate, setGroupedByDate] = useState({});

 // 초기 데이터 로드
 useEffect(() => {
   fetchResources();
   fetchDates();
   fetchTimes();
   fetchAllAvailableSlots();
 }, [type]);

 // 선택된 리소스와 날짜가 변경될 때 가용 시간 조회 
 useEffect(() => {
   if (selectedResource && selectedDate) {
     fetchAvailableSlots();
   }
 }, [selectedResource, selectedDate]);

 // 검색 조건이 변경될 때마다 필터링
 useEffect(() => {
   const filtered = allAvailableSlots.reduce((acc, slot) => {
     const resource = type === 'tour' ? slot.docent : slot.room;
     const date = type === 'tour' ? slot.event_date.date : slot.event_date.date;
     
     // 검색 조건 적용
     if (searchResource && resource.id.toString() !== searchResource) return acc;
     if (searchDate && date !== searchDate) return acc;
     
     if (!acc[date]) {
       acc[date] = {};
     }
     
     if (!acc[date][resource.name]) {
       acc[date][resource.name] = {
         resourceId: resource.id,
         id: slot.id,
         slots: []
       };
     }
     
     acc[date][resource.name].slots.push({
       id: slot.id,
       time: type === 'tour' ? slot.tour_time.time : slot.room_time.time 
     });
     
     // 시간순 정렬
     acc[date][resource.name].slots.sort((a, b) => a.time.localeCompare(b.time));
     
     return acc;
   }, {});

   // 날짜로 정렬된 객체 생성
   const orderedDates = Object.keys(filtered)
     .sort((a, b) => a.localeCompare(b))
     .reduce((acc, date) => {
       // 각 날짜 내의 리소스를 이름순으로 정렬
       const orderedResources = Object.keys(filtered[date])
         .sort()
         .reduce((resourceAcc, resourceName) => {
           resourceAcc[resourceName] = filtered[date][resourceName];
           return resourceAcc;
         }, {});

       acc[date] = orderedResources;
       return acc;
     }, {});

   setGroupedByDate(orderedDates);
 }, [searchResource, searchDate, allAvailableSlots]);

 // 전체 가용 시간 조회
 const fetchAllAvailableSlots = async () => {
   try {
     const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
     const response = await api.get(endpoint);
     setAllAvailableSlots(response.data);
   } catch (error) {
     console.error('Error fetching all available slots:', error);
     toast.error('가용 시간 목록을 불러오는데 실패했습니다.');
   }
 };

 // 도슨트/미팅룸 목록 조회
 const fetchResources = async () => {
   try {
     const endpoint = type === 'tour' ? '/api/admin/docents/' : '/api/admin/rooms/';
     const response = await api.get(endpoint);
     setResources(response.data);
   } catch (error) {
     console.error('Error fetching resources:', error);
     toast.error('목록을 불러오는데 실패했습니다.');
   }
 };

 // 날짜 목록 조회
 const fetchDates = async () => {
   try {
     const response = await api.get('/api/admin/event-dates/');
     setDates(response.data);
   } catch (error) {
     console.error('Error fetching dates:', error);
     toast.error('날짜 목록을 불러오는데 실패했습니다.');
   }
 };

 // 시간 목록 조회
 const fetchTimes = async () => {
   try {
     const endpoint = type === 'tour' ? '/api/admin/tour-times/' : '/api/admin/room-times/';
     const response = await api.get(endpoint);
     setTimes(response.data);
   } catch (error) {
     console.error('Error fetching times:', error);
     toast.error('시간 목록을 불러오는데 실패했습니다.');
   }
 };

 // 가용 시간 조회
 const fetchAvailableSlots = async () => {
   try {
     const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
     const params = {
       [type === 'tour' ? 'docent_id' : 'room_id']: selectedResource,
       date: selectedDate
     };
     const response = await api.get(endpoint, { params });
     setAvailableSlots(response.data);
     
     const availableTimeIds = response.data.map(slot => 
       type === 'tour' ? slot.tour_time.id : slot.room_time.id
     );
     setSelectedTimes(availableTimeIds);
   } catch (error) {
     console.error('Error fetching available slots:', error);
     toast.error('가용 시간을 불러오는데 실패했습니다.');
   }
 };

 // 전체 선택/해제 처리
 const handleSelectAll = (e) => {
   if (e.target.checked) {
     setSelectedTimes(times.map(time => time.id));
   } else {
     setSelectedTimes([]);
   }
 };

 // 가용 시간 저장
 const handleSubmit = async (e) => {
   e.preventDefault();
   try {
     const endpoint = type === 'tour' ? '/api/admin/available-tours/' : '/api/admin/available-rooms/';
     const dateId = dates.find(d => d.date === selectedDate)?.id;
     
     if (!dateId) {
       toast.error('유효하지 않은 날짜입니다.');
       return;
     }

     const data = {
       [`${type === 'tour' ? 'docent' : 'room'}_id`]: selectedResource,
       event_date_id: dateId,
       [`${type === 'tour' ? 'tour' : 'room'}_time_ids`]: selectedTimes
     };

     await api.post(endpoint, data);
     toast.success('가용 시간이 설정되었습니다.');
     await fetchAllAvailableSlots();
     fetchAvailableSlots();
   } catch (error) {
     console.error('Error saving availability:', error);
     toast.error('가용 시간 설정에 실패했습니다.');
   }
 };

 // 단일 가용 시간 삭제
 const handleDeleteSlot = async (id) => {
   if (!window.confirm('선택한 가용 시간을 삭제하시겠습니까?')) return;

   try {
     const endpoint = type === 'tour' 
       ? `/api/admin/available-tours/${id}/` 
       : `/api/admin/available-rooms/${id}/`;
     
     await api.delete(endpoint);
     toast.success('가용 시간이 삭제되었습니다.');
     fetchAllAvailableSlots();
   } catch (error) {
     console.error('Error deleting slot:', error);
     toast.error('가용 시간 삭제에 실패했습니다.');
   }
 };
 // 리소스의 모든 가용 시간 한 번에 삭제하는 함수 추가
 const handleDeleteResourceSlots = async (resourceId, date) => {
  try {
    const endpoint = type === 'tour' 
      ? '/api/admin/available-tours/' 
      : '/api/admin/available-rooms/';
    
    const params = {
      [type === 'tour' ? 'docent_id' : 'room_id']: resourceId,
      date: date
    };

    await api.delete(endpoint, { params });
    toast.success('가용 시간이 삭제되었습니다.');
    fetchAllAvailableSlots();
  } catch (error) {
    console.error('Error deleting resource slots:', error);
    toast.error('가용 시간 삭제에 실패했습니다.');
  }
};

 // 필터링된 가용 시간 전체 삭제
 const handleDeleteFilteredSlots = async () => {
   const message = searchResource || searchDate
     ? '필터링된 가용 시간을 모두 삭제하시겠습니까?'
     : '모든 가용 시간을 삭제하시겠습니까?';

   if (!window.confirm(message)) return;

   try {
     const endpoint = type === 'tour' 
       ? '/api/admin/available-tours/' 
       : '/api/admin/available-rooms/';
     
     const params = {};
     if (searchResource) {
       params[type === 'tour' ? 'docent_id' : 'room_id'] = searchResource;
     }
     if (searchDate) {
       params.date = searchDate;
     }

     await api.delete(endpoint, { params });
     toast.success('선택된 가용 시간이 모두 삭제되었습니다.');
     fetchAllAvailableSlots();
   } catch (error) {
     console.error('Error deleting filtered slots:', error);
     toast.error('가용 시간 삭제에 실패했습니다.');
   }
 };

 return (
   <div className="space-y-6">
     {/* 입력 폼 섹션 */}
     <div className="p-6 bg-white rounded-lg shadow">
       <h2 className="mb-6 text-xl font-semibold">
         {type === 'tour' ? '도슨트 가용 시간 관리' : '미팅룸 가용 시간 관리'}
       </h2>

       <form onSubmit={handleSubmit} className="space-y-6">
         <div className="grid grid-cols-2 gap-6">
           <div>
             <label className="block mb-2 text-sm font-medium text-gray-700">
               {type === 'tour' ? '도슨트' : '미팅룸'} 선택
             </label>
             <select
               value={selectedResource}
               onChange={(e) => setSelectedResource(e.target.value)}
               className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
               required
             >
               <option value="">선택해주세요</option>
               {resources.map((resource) => (
                 <option key={resource.id} value={resource.id}>
                   {resource.name}
                 </option>
               ))}
             </select>
           </div>
           <div>
             <label className="block mb-2 text-sm font-medium text-gray-700">
               날짜 선택
             </label>
             <select
               value={selectedDate}
               onChange={(e) => setSelectedDate(e.target.value)}
               className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
               required
             >
               <option value="">선택해주세요</option>
               {dates.map((date) => (
                 <option key={date.id} value={date.date}>
                   {date.date}
                 </option>
               ))}
             </select>
           </div>
         </div>

         <div className="space-y-4">
           <div className="flex items-center justify-between">
             <label className="text-sm font-medium text-gray-700">
               시간 선택
             </label>
             <label className="flex items-center space-x-2">
               <input
                 type="checkbox"
                 checked={selectedTimes.length === times.length}
                 onChange={handleSelectAll}
                 className="text-blue-600 border-gray-300 rounded focus:ring-blue-500"
               />
               <span className="text-sm text-gray-600">전체 선택</span>
             </label>
           </div>
           <div className="grid grid-cols-6 gap-3 p-4 rounded-lg bg-gray-50">
             {times.map((time) => (
               <label
                 key={time.id}
                 className="flex items-center p-2 space-x-2 transition-colors bg-white rounded shadow-sm hover:bg-gray-50"
               >
                 <input
                   type="checkbox"
                   checked={selectedTimes.includes(time.id)}
                   onChange={(e) => {
                     if (e.target.checked) {
                       setSelectedTimes([...selectedTimes, time.id]);
                     } else {
                       setSelectedTimes(selectedTimes.filter(id => id !== time.id));
                     }
                   }}
                   className="text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                 />
                 <span className="text-sm font-medium">{time.time}</span>
               </label>
             ))}
           </div>
         </div>

         <div className="flex justify-end">
           <button
             type="submit"
             className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
             disabled={!selectedResource || !selectedDate || selectedTimes.length === 0}
           >
             가용 시간 설정
           </button>
         </div>
       </form>
     </div>

     {/* 가용 시간 목록 섹션 */}
     <div className="p-6 bg-white rounded-lg shadow">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-medium">설정된 가용 시간 목록</h3>
          <div className="flex gap-4">
            <div className="flex items-center gap-4">
              <select
                value={searchResource}
                onChange={(e) => setSearchResource(e.target.value)}
                className="px-4 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">전체 {type === 'tour' ? '도슨트' : '미팅룸'}</option>
                {resources.map((resource) => (
                  <option key={resource.id} value={resource.id}>
                    {resource.name}
                  </option>
                ))}
              </select>
              <select
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
                className="px-4 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">전체 날짜</option>
                {dates.map((date) => (
                  <option key={date.id} value={date.date}>
                    {date.date}
                  </option>
                ))}
              </select>
              <button
                onClick={handleDeleteFilteredSlots}
                className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                {searchResource || searchDate ? '필터 항목 삭제' : '전체 삭제'}
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-8">
          {Object.keys(groupedByDate).length > 0 ? (
            Object.entries(groupedByDate).map(([date, resources]) => (
              <div key={date} className="p-4 border rounded-lg">
                <h4 className="pb-2 mb-4 text-lg font-medium border-b">{date}</h4>
                <div className="space-y-6">
                  {Object.keys(resources).length > 0 ? (
                    Object.entries(resources).map(([resourceName, data]) => (
                      <div key={`${date}_${resourceName}`} className="p-4 ml-4 rounded-lg bg-gray-50">
                        <div className="flex items-center justify-between mb-3">
                          <h5 className="font-semibold text-md">{resourceName}</h5>
                          <button
                            onClick={() => {
                              if (window.confirm(`${resourceName}의 모든 가용 시간을 삭제하시겠습니까?`)) {
                                handleDeleteResourceSlots(data.resourceId, date);
                              }
                            }}
                            className="text-sm text-red-600 hover:text-red-800"
                          >
                            전체 삭제
                          </button>
                        </div>
                        <div className="grid grid-cols-6 gap-2">
                         {data.slots.map((slot) => (
                          <div
                            key={slot.id}
                            className="relative group"
                          >
                            <div className="px-3 py-2 text-sm text-center bg-white rounded shadow-sm">
                              {slot.time}
                            </div>
                            <button
                              onClick={() => handleDeleteSlot(slot.id)}
                              className="absolute top-0 right-0 hidden p-1 -mt-2 -mr-2 text-xs text-red-600 bg-white rounded-full shadow-sm group-hover:block hover:text-red-800"
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                      </div>
                    ))
                  ) : (
                    <p className="ml-4 text-gray-500">설정된 시간이 없습니다.</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="py-4 text-center text-gray-500">
              {searchResource || searchDate ? '검색 결과가 없습니다.' : '설정된 가용 시간이 없습니다.'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}