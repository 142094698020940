import { useState, useEffect } from 'react';
import api from '../api/axios';

export function useAdminDashboard() {
 const [data, setData] = useState({
   dates: [],
   tourTimeSlots: [],
   meetingTimeSlots: [],
   docents: [],
   rooms: [],
   approvedReservations: {},
   pendingReservations: {},
   stats: {
     pending_tours: 0,
     pending_meetings: 0,
     today_tours: 0,
     today_meetings: 0
   },
   loading: true,
   error: null
 });

 const fetchDashboardData = async () => {
   
   try {
     setData(prev => ({ ...prev, loading: true, error: null }));

     // 기본 데이터 병렬로 가져오기
     const [
       datesRes, 
       tourTimesRes, 
       meetingTimesRes, 
       docentsRes, 
       roomsRes,
       statsRes,
       reservationsRes
     ] = await Promise.all([
       api.get('/api/admin/event-dates/'),
       api.get('/api/admin/tour-times/'),
       api.get('/api/admin/room-times/'),
       api.get('/api/admin/docents/'),
       api.get('/api/admin/rooms/'),
       api.get('/api/admin/dashboard/stats/'),
       api.get('/api/admin/reservations/') // 통합 API 호출
     ]);

     const { tours, meetings } = reservationsRes.data;
     const reservationsData = {};
     const pendingData = {};

     // 날짜별로 데이터 구조화
     datesRes.data.forEach(date => {
       const dateStr = date.date;
       
       reservationsData[dateStr] = {
         tours: tours.filter(tour => 
           tour.available_tour.event_date.date === dateStr && 
           tour.status === 'APPROVED'
         ),
         meetings: meetings.filter(meeting => 
           meeting.available_room.event_date.date === dateStr && 
           meeting.status === 'APPROVED'
         )
       };

       pendingData[dateStr] = {
         tours: tours.filter(tour => 
           tour.available_tour.event_date.date === dateStr && 
           tour.status === 'PENDING'
         ),
         meetings: meetings.filter(meeting => 
           meeting.available_room.event_date.date === dateStr && 
           meeting.status === 'PENDING'
         )
       };
     });

     setData({
       dates: datesRes.data,
       tourTimeSlots: tourTimesRes.data,
       meetingTimeSlots: meetingTimesRes.data,
       docents: docentsRes.data,
       rooms: roomsRes.data,
       approvedReservations: reservationsData,
       pendingReservations: pendingData,
       stats: statsRes.data,
       loading: false,
       error: null
     });
   } catch (error) {
     console.error('Error fetching dashboard data:', error);
     setData(prev => ({
       ...prev,
       loading: false,
       error: '데이터를 불러오는데 실패했습니다.'
     }));
   }
 };

 useEffect(() => {
   fetchDashboardData();
 }, []);

 return {
   ...data,
   refreshData: fetchDashboardData
 };
}