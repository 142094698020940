import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import api from '../../../api/axios';
import { useEffect } from 'react';

export default function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //세션 체크
    const checkSession = async () => {
      try {
        const response = await api.get('/api/admin/tours/');
        // response.status가 200번대가 아니면 로그인 페이지로 이동
        if (response.status < 200 || response.status >= 300) {
          navigate('/admin');
        }
      } catch (error) {
        // API 호출 실패 시 로그인 페이지로 이동
        navigate('/admin');
      }
    };

    checkSession();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await api.post('/api/admin/logout/');
      navigate('/admin');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <header className="bg-white shadow">
      <div className="max-w-[99%] mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-4xl font-bold text-sky-700">CES2025 SK</h1>
          <nav className="flex space-x-4">
            <button
              onClick={() => navigate('/admin/dashboard')}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/admin/dashboard'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-500 hover:text-gray-900'
              }`}
            >
              대시보드
            </button>
            <button
              onClick={() => navigate('/admin/users')}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/admin/users'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-500 hover:text-gray-900'
              }`}
            >
              회원 관리
            </button>
            <button
              onClick={() => navigate('/admin/reservation')}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/admin/reservation'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-500 hover:text-gray-900'
              }`}
            >
              예약 관리
            </button>
            <button
              onClick={() => navigate('/admin/settings')}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/admin/settings'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-500 hover:text-gray-900'
              }`}
            >
              설정
            </button>
          </nav>
        </div>
        <button
          onClick={handleLogout}
          className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700"
        >
          로그아웃
        </button>
      </div>
    </header>
  );
}